import { useAddress } from '@hooks/use-address';
import { useBalance } from '@hooks/use-balances';
import { useTransaction } from '@hooks/use-transaction';
import { pumpSdk } from '@libs/pump';
import { transactionModel } from '@models/transaction.model';
import { walletConnectModel } from '@models/wallet-connect.model';
import { Transaction } from '@mysten/sui/transactions';
import { type LaunchData } from '@pages/launch';
import Dialog from '@ui/dialog';
import Input from '@ui/input';
import Lottie from 'lottie-react';
import { useEffect, useMemo, useState, type FC } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './index.module.scss';
import gearsrotatingJSON from '@assets/lottie/pump/gearsrotating.json';
import starJSON from '@assets/lottie/pump/stars.json';
import createErrorImg from '@assets/images/pump/create-error.svg';
import { usePumpCalculateTokenAndThresholdAmount } from '@hooks/use-pump-amount';
import { useDebounce } from 'react-use';
import { filterPositiveNumber } from '@utils/filter-positive-number';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { type PreheatData } from '@hooks/use-fun';
import { auth } from '@libs/auth';
import { useSignPersonalMessage } from '@mysten/dapp-kit';
import http from '@libs/http';
import { ratioList } from '@components/pump-buy-and-sell/buy';

interface OwnerProps {
  data: LaunchData;
  clearData(): void;
  funData?: PreheatData;
}

const SubmitLaunch: FC<OwnerProps> = ({ data, clearData, funData }) => {
  const address = useAddress();
  const transact = useTransaction();
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState('');
  const balance = useBalance(pumpSdk.quote_address);
  const { mutateAsync: signMessage } = useSignPersonalMessage();
  // const [isExact, setIsExact] = useState(true);
  const [ratio, setRatio] = useState('');

  const [finalAmount, setFinalAmount] = useState('');
  const [coin, setCoin] = useState<
    | {
        coinTreasuryCap: string;
        coinType: string;
      }
    | undefined
  >();

  useDebounce(
    () => {
      setFinalAmount(amount);
    },
    60,
    [amount],
  );

  const balanceAmount = new Decimal(balance || '0').div(10 ** pumpSdk.quote_decimals).toString();

  let calculateAmount = !!finalAmount ? finalAmount : '0';
  let calculateIsExact = true;

  if (!!ratio) {
    calculateAmount = pumpSdk.getTokenAmountForRatio(ratio);
    calculateIsExact = false;
  }

  const receive = usePumpCalculateTokenAndThresholdAmount({
    coinType: coin?.coinType || '',
    amount: new Decimal(calculateAmount)
      .mul(10 ** (!!ratio ? pumpSdk.token_decimals : pumpSdk.quote_decimals))
      .toString(),
    threshold: '1',
    atob: true,
    isExact: calculateIsExact,
    isInit: true,
  });
  const receiveAmountRatio = pumpSdk.getTokenRatioForAmount(receive?.tokenAmount || '0');

  const receiveAmount = new Decimal(receive?.tokenAmount || '0')
    .div(10 ** (!!ratio ? pumpSdk.quote_decimals : pumpSdk.token_decimals))
    .toString();

  useEffect(() => {
    if (!!ratio) {
      setAmount(receiveAmount);
    }
  }, [receiveAmount, ratio]);

  const [confirmText, confirmDisabled] = useMemo(() => {
    if (!amount || receiveAmount === '0') {
      return ['', true];
    } else if (new Decimal(balanceAmount).sub(amount).sub(pumpSdk.deployment_fee).lte(0)) {
      return ['Insufficient balance', true];
    }
    return ['', false];
  }, [amount, receiveAmount, pumpSdk.deployment_fee, balanceAmount]);

  let finalRatio = !!ratio ? ratio : receiveAmountRatio;
  let finalReceiveAmount = !!ratio ? calculateAmount : receiveAmount;
  const availableAmount = new Decimal(pumpSdk.initial_virtual_token_reserves)
    .sub(pumpSdk.remain_token_reserves)
    .div(10 ** pumpSdk.token_decimals)
    .toString();

  const isGt = new Decimal(finalReceiveAmount).gt(availableAmount);
  finalReceiveAmount = isGt ? availableAmount : finalReceiveAmount;
  finalRatio = isGt
    ? new Decimal(pumpSdk.initial_virtual_token_reserves)
        .sub(pumpSdk.remain_token_reserves)
        .div(pumpSdk.initial_virtual_token_reserves)
        .mul(100)
        .toString()
    : finalRatio;

  const onClose = () => {
    setVisible(false);
  };

  const publish = async () => {
    if (!address) return;
    if (funData && !funData.is_published) {
      const token = await auth(address, signMessage, true);

      await http.patch(
        '/preheat/publish',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }
  };

  const create = async () => {
    if (!address) return;
    const { status, digest } = await transact(
      'Pushing token ... (1/2)',
      async () => {
        return pumpSdk.publish({
          name: data.name,
          symbol: data.symbol,
          url: data.url,
          description: data.description,
          currentAddress: address,
        });
      },
      {
        confirm: (
          <>
            <Lottie animationData={gearsrotatingJSON} autoplay loop className={styles.lottie} />
            <p>Launching ${data.symbol} Token</p>
          </>
        ),
        errorTip: (
          <>
            <img src={createErrorImg} />
            <p>Pushing token ... (1/2)</p>
          </>
        ),
      },
    );
    if (status && digest) {
      transactionModel.toggleVisible();
      setVisible(true);
      const result = await pumpSdk.getCoinTreasuryCap(digest);
      setCoin(result);
      publish();
    }
  };

  const confirm = async (value: boolean) => {
    if (!address || !coin) return;

    let txb = new Transaction();
    const { status } = await transact(
      'Pushing token ... (2/2)',
      async () => {
        txb = await pumpSdk.createPool({
          ...data,
          ...coin,
          currentAddress: address,
          tx: txb,
        });

        if (!value) {
          return txb;
        }

        return pumpSdk.buy({
          coinType: coin.coinType,
          amount: isGt ? finalReceiveAmount : calculateAmount,
          walletAddress: address,
          tx: txb,
          isExact: !!ratio || isGt ? false : true,
          threshold: '1',
        });
      },
      {
        confirm: (
          <>
            <Lottie animationData={gearsrotatingJSON} autoplay loop className={styles.lottie} />
            <p>Launching ${data.symbol} Token</p>
          </>
        ),
        errorTip: (
          <>
            <img src={createErrorImg} />
            <p>Pushing token ... (2/2)</p>
          </>
        ),
        description: <></>,
        successTip: (
          <>
            <div className={styles.img}>
              <img src={data.url} />
              <Lottie animationData={starJSON} autoplay loop className={styles.lottie} />
            </div>
            <p>${data.symbol} launched successfully!</p>
          </>
        ),
      },
    );

    if (status) {
      setVisible(false);
      setAmount('');
      clearData();
    }
  };

  const handleAmount = (ratio: number) => {
    if (!balance) return;
    setAmount(
      new Decimal(balanceAmount)
        .mul(ratio)
        .sub(ratio === 1 ? 0.1 : 0) // 0.1 sui gas
        .toString(),
    );
    setRatio('');
  };

  const changeAmount = (amount: string) => {
    setRatio('');
    const result = filterPositiveNumber(amount);
    setAmount(result.str);
  };

  const [launch, launchDisabled, launchEvent] = useMemo(() => {
    const fn = async () => {};
    if (!address) {
      return ['Connect Wallet', false, walletConnectModel.toggleDialog];
    } else if (!data.url || !data.name || !data.symbol || !data.description) {
      return ['Launch', true, fn];
    } else if (!/[A-Z]+/g.test(data.symbol)) {
      return ['Launch', true, fn];
    } else if (new Decimal(balanceAmount).lte(pumpSdk.deployment_fee)) {
      return ['Insufficient balance', true, fn];
    } else if (data.name === data.symbol) {
      return ['Launch', true, fn];
    }
    return ['Launch', false, create];
  }, [
    JSON.stringify(data),
    create,
    address,
    walletConnectModel.toggleDialog,
    balanceAmount,
    pumpSdk.deployment_fee,
  ]);

  return (
    <>
      <div className={styles.div}>
        <button className={styles.button} onClick={launchEvent} disabled={launchDisabled}>
          {launch}
        </button>
      </div>

      <Dialog visible={visible} onClose={onClose} title={'Turbos'} closable width={460}>
        <div className={styles.wrapper}>
          <div className={styles.text1}>Launch ${data.symbol} Token</div>
          <div className={styles.text2}>
            Choose how many ${data.symbol}
            <br />
            you want to buy
          </div>

          {/* <div className={styles.switch}>
            <div className={styles.switch_btn} onClick={() => setIsExact(!isExact)}>
              switch to {isExact ? pumpSdk.quote_symbol : data.symbol}
            </div>
          </div> */}
          <Input
            placeholder="Enter the amount"
            wrapperClassName={styles.input}
            value={amount}
            onChangeText={changeAmount}
            suffix={
              <>
                <div className={styles.coin}>
                  <span>{pumpSdk.quote_symbol}</span>
                  <img src={pumpSdk.quote_logo_url} />
                </div>
                <div className={styles.balance}>
                  Balance: {new Decimal(balance || 0).div(10 ** pumpSdk.quote_decimals).toString()}
                  <div onClick={() => handleAmount(0.5)}>Half</div>
                  <div onClick={() => handleAmount(1)}>Max</div>
                </div>
              </>
            }
          />

          {confirmText && <div className={styles.error}>{confirmText}</div>}

          <>
            <div className={styles.or_select}>Or choose a % of ${data.symbol} total supply</div>
            <ul className={styles.sui_tab}>
              {ratioList.map((item) => {
                return (
                  <li
                    onClick={() => {
                      setRatio(item.value);
                    }}
                    key={item.value}
                    className={`${ratio === item.value ? styles.active : ''}`}
                  >
                    {item.label}%
                  </li>
                );
              })}
            </ul>
          </>
          <div className={styles.line}>
            <p>You will receive:</p>
            <p>
              {bigNumberToReadable(finalReceiveAmount, 0, true)}{' '}
              {finalRatio !== '0' ? `(${bigNumberToReadable(finalRatio, 0, false, 2)}%)` : null}{' '}
              {data.symbol}
            </p>
          </div>

          <div className={styles.line}>
            <p>Deployment Fee:</p>
            <p>{pumpSdk.deployment_fee} SUI</p>
          </div>

          <div className={styles.bottom}>
            <button className={styles.skip} onClick={() => confirm(false)}>
              Skip
            </button>
            <button
              className={styles.comfirm}
              onClick={() => confirm(true)}
              disabled={confirmDisabled}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SubmitLaunch;
