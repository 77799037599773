import { Navigate, type RouteObject } from 'react-router-dom';
import App from '@components/app';
import Launch from '@pages/launch';
import Pump from '@pages/pump';
import Ranking from '@pages/ranking';
import PumpDetail from '@pages/pump-detail';
import Portfolio from '@pages/portfolio';

const subRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/fun" />,
  },
  {
    path: '/launch',
    element: <Launch />,
  },
  {
    path: '/fun',
    element: <Pump />,
  },
  {
    path: '/fun/:id',
    element: <PumpDetail />,
  },
  {
    path: '/Ranking',
    element: <Ranking />,
  },
  {
    path: '/portfolio',
    element: <Portfolio />,
  },
  {
    path: '*',
    element: <Navigate to="/launch" />,
  },
  // {
  //   path: '/referral-link',
  //   lazy: async () => lazyLoad(import('@pages/yield-strategy/referral-link')),
  // },
];

export const routerObjects: RouteObject[] = [
  {
    element: <App />,
    children: subRoutes,
  },
];

// const lazyLoad = async (dynamicImport: Promise<{ default: FC }>) => {
//   const obj = await dynamicImport;
//   return {
//     Component: obj.default,
//   };
// };
