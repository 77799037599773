import 'rc-tooltip/assets/bootstrap.css';
import { type FC, memo } from 'react';
import RCTooltip from 'rc-tooltip';
import styles from './index.module.scss';
import type { TooltipProps as OriginProps } from 'rc-tooltip/lib/Tooltip';

export interface TooltipProps extends OriginProps {
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
  /**
   * Defaults `true`
   */
  onlyMessage?: boolean;
  /**
   * Defaults `false`
   */
  hasBorder?: boolean;
}

const ToolTip: FC<TooltipProps> = ({ onlyMessage = false, hasBorder = false, ...props }) => {
  return (
    <RCTooltip
      destroyTooltipOnHide
      showArrow
      {...props}
      overlayClassName={`${styles.tooltip} ${onlyMessage ? styles.only_message : ''} ${
        hasBorder ? styles.has_border : ''
      } ${props.overlayClassName}`}
    ></RCTooltip>
  );
};

export default memo(ToolTip);
