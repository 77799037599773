import { globalSettingModel } from '@models/global-setting.model';
import { useModel } from 'foca';
import { type FC } from 'react';
import styles from './setting-preferred-explorer.module.scss';
import exploreIcon from '@assets/images/icons/explore.svg';
import arrowDownIcon from '@assets/images/icons/arrow-down.svg';
import { startCase } from 'lodash-es';

interface OwnProps {
  toggleSelecting(): void;
}

const SettingPreferredExplorer: FC<OwnProps> = ({ toggleSelecting }) => {
  const explorerKey = useModel(globalSettingModel, (state) => state.explorerKey);
  return (
    <>
      <p className={styles.preferred}>
        <img src={exploreIcon} data-icon />
        Preferred Explorer
      </p>
      <div className={styles.selected} onClick={toggleSelecting}>
        {startCase(explorerKey)}
        <img src={arrowDownIcon} />
      </div>
    </>
  );
};

export default SettingPreferredExplorer;
