import { pumpApi } from '@libs/openapi';
import { OpenapiClientPump } from 'foca-openapi';
import { useEffect, useState } from 'react';

export const usePumpOwned = (options: { owner: string }) => {
  const [loading, setLoading] = useState(false);
  const [owned, setOwned] = useState<OpenapiClientPump.GetTokenCoinsOwnedResponse>({
    page: 1,
    pageSize: 5000,
    total: 0,
    data: [],
  });

  const getRankingsBoundingCurve = async () => {
    if (!options.owner) {
      setOwned({
        page: 1,
        pageSize: 10,
        total: 0,
        data: [],
      });
      return;
    }
    setLoading(true);
    const result = await pumpApi.coin.getTokenCoinsOwned({
      query: { owner: options.owner, page: 1, pageSize: 100 },
    });
    setOwned(result);
    setLoading(false);
  };
  useEffect(() => {
    getRankingsBoundingCurve();
  }, [JSON.stringify(options)]);

  return {
    owned,
    loading,
  };
};
