export const menus: {
  path: string;
  name: string;
  key: string;
  target?: string;
}[] = [
  {
    path: '/fun',
    name: 'Turbos.Fun',
    key: 'fun',
  },
  {
    path: '/launch',
    name: 'Create Token',
    key: 'launch',
  },
  {
    path: '/Ranking',
    name: 'Ranking',
    key: 'ranking',
  },
];
