import { type CalculateAmountOptions, pumpSdk } from '@libs/pump';
import { isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';

export const usePumpCalculateTokenAndThresholdAmount = (options: CalculateAmountOptions) => {
  const [receive, setReceive] = useState<
    { tokenAmount: string; thresholdAmount: string } | undefined
  >();
  const [_, setPrev] = useState<CalculateAmountOptions>();

  useEffect(() => {
    let opExpired: boolean = false;
    const calculateTokenAndThresholdAmount = async () => {
      if (!options.amount || !options.coinType || opExpired) {
        setReceive(undefined);
        return;
      }

      if (!options.isInit) {
        setPrev((pre) => {
          if (!isEqual(options, pre)) {
            setReceive(undefined);
          }
          return pre;
        });
      }

      const result = await pumpSdk.calculateTokenAndThresholdAmount(options);
      if (opExpired) {
        setReceive(undefined);
        return;
      }
      setReceive(result);
      setPrev(options);
    };
    calculateTokenAndThresholdAmount();

    return () => {
      opExpired = true;
    };
  }, [JSON.stringify(options)]);

  return receive;
};
