import { getWalletIcon, walletList, zkLoginList } from '@constants/wallet-list';
import { walletConnectModel } from '@models/wallet-connect.model';
import { useConnectWallet, useDisconnectWallet, useWallets } from '@mysten/dapp-kit';
import Dialog from '@ui/dialog';
import { useModel } from 'foca';
import { type FC } from 'react';
import CoinLogo from '@ui/coin-logo';
import { toast } from 'react-toastify';
import styles from './wallet-dialog.module.scss';

const WalletDialog: FC = () => {
  const wallets = useWallets();
  const { mutate: connect } = useConnectWallet();
  const { mutate: disconnect } = useDisconnectWallet();
  const showDialog = useModel(walletConnectModel, (state) => state.openDialog);

  return (
    <Dialog
      centered
      visible={showDialog}
      className={styles.dialog}
      width={776}
      title="Sign In"
      onClose={walletConnectModel.toggleDialog}
    >
      <div className={styles.wallet_list}>
        <div className={styles.wallet_right}>
          <ul>
            {zkLoginList
              .filter((item) => !item.hidden)
              .map((item) => {
                const wallet = wallets.find((wallet) => wallet.name === item.walletName);
                return (
                  <li
                    key={item.label}
                    onClick={() => {
                      walletConnectModel.toggleDialog();
                      if (wallet) {
                        disconnect();
                        connect({
                          wallet,
                        });
                      } else {
                        toast(
                          <>
                            <p>Wallet is not ready, please install extension first</p>
                          </>,
                          {
                            type: 'error',
                          },
                        );
                      }
                    }}
                  >
                    <p>
                      <CoinLogo src={getWalletIcon(item.iconName)} />
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className={styles.wallet_left}>
          <div className={styles.wallet_title}>or Connect Wallet</div>
          <ul>
            {walletList
              .filter((item) => !item.hidden)
              .map((item) => {
                const wallet = wallets.find((wallet) => wallet.name === item.walletName);
                return (
                  <li
                    key={item.walletName}
                    onClick={() => {
                      walletConnectModel.toggleDialog();
                      if (wallet) {
                        disconnect();
                        connect({
                          wallet,
                        });
                      } else {
                        toast(
                          <>
                            <p>
                              Wallet is not ready, please install&nbsp;
                              <a href={item.chromeExtension} target="_blank">
                                {item.walletName}
                              </a>
                              &nbsp; extension first
                            </p>
                          </>,
                          {
                            type: 'error',
                          },
                        );
                      }
                    }}
                  >
                    <p>
                      <CoinLogo src={getWalletIcon(item.iconName) || wallet?.icon} />
                      {item.label}
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </Dialog>
  );
};

export default WalletDialog;
