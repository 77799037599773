import { FC } from 'react';
import styles from './index.module.scss';

const Banner: FC = () => {
  return (
    <div className={styles.banner}>
      <div
        className={`${styles.slide} ${styles.usdc}`}
        onClick={() => {
          window.open(
            'https://x.com/turbos_finance/status/1859413545341026693?s=46&t=_DwJHbIUyl1im0POFafmDQ',
          );
        }}
      ></div>
    </div>
  );
};

export default Banner;
