import { type FC } from 'react';
import styles from './index.module.scss';
import completedIcon from '@assets/images/pump/completed.svg';

const PumpCompleted: FC = () => {
  return (
    <div className={styles.buy_sell}>
      <div className={styles.img}>
        <img src={completedIcon} />
      </div>
      <div className={styles.completed}>Launch completed!</div>
      <ul className={styles.text}>
        <li>A Turbos pool will be seeded in the next 5-20 minutes.</li>
        <li>A link to the pool will be provided here once complete.</li>
        <li>Only trust the link that is posted.</li>
      </ul>
    </div>
  );
};

export default PumpCompleted;
