import mainnet, { type Config } from './mainnet';
import devnet from './devnet';
import testnet from './testnet';

const configs = {
  mainnet,
  testnet,
  devnet,
};

export const config: Config = configs[(import.meta.env.MODE || 'devnet') as keyof typeof configs];
