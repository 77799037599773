import { type PoolFields, pumpSdk } from '@libs/pump';
import { useEffect, useState } from 'react';
import { useRefreshFlag } from './use-refresh';

export const usePool = (tokenAddress: string) => {
  const { refresh } = useRefreshFlag();
  const [pool, setPool] = useState<PoolFields>();

  const getPool = async () => {
    if (tokenAddress) {
      try {
        const res = await pumpSdk.getPoolInfo(tokenAddress, false);
        setPool(res);
        return;
      } catch (err) {}
    }
    setPool(undefined);
  };
  useEffect(() => {
    getPool();
  }, [tokenAddress, refresh]);

  return pool;
};
