import { globalSettingModel } from '@models/global-setting.model';
import { useResolveSuiNSName } from '@mysten/dapp-kit';
import { formatAddress } from '@mysten/sui/utils';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { timeDifference } from '@utils/time-difference';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './list.module.scss';

interface OwnerProps {
  item: OpenapiClientPump.GetTradeHistoryResponse['data'][number];
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const List: FC<OwnerProps> = ({ item, pool }) => {
  const suiNames = useResolveSuiNSName(item.sender);
  return (
    <li key={item.tx_digest}>
      <div className={styles.line}>
        <p>Account</p>
        <p>
          <Link to={globalSettingModel.explorerUrlForAccount(item.sender)} target="_blank">
            {suiNames.data ? suiNames.data : formatAddress(item.sender)}
          </Link>
        </p>
      </div>
      <div className={styles.line}>
        <p>Type</p>
        <p className={item.is_buy ? styles.green : styles.red}> {item.is_buy ? 'Buy' : 'Sell'}</p>
      </div>
      <div className={styles.line}>
        <p>SUI</p>
        <p>{item.sui_amount}</p>
      </div>
      <div className={styles.line}>
        <p>${pool.symbol}</p>
        <p>{bigNumberToReadable(item.token_amount, 0, true)}</p>
      </div>
      <div className={styles.line}>
        <p>Date</p>
        <p>{timeDifference(item.timestamp)} ago</p>
      </div>
      <div className={styles.line}>
        <p>Transaction</p>
        <p>
          <Link to={globalSettingModel.explorerUrlForTransaction(item.tx_digest)} target="_blank">
            {formatAddress(item.tx_digest)}
          </Link>
        </p>
      </div>
    </li>
  );
};

export default List;
