import { pumpApi } from '@libs/openapi';
import { OpenapiClientPump } from 'foca-openapi';
import { useEffect, useState } from 'react';

export const usePumpHolder = (tokenAddress: string) => {
  const [holders, setHolders] = useState<OpenapiClientPump.GetTokenCoinsHoldersResponse>();

  const getRankingsBoundingCurve = async () => {
    if (!tokenAddress) {
      setHolders(undefined);
      return;
    }
    const result = await pumpApi.coin.getTokenCoinsHolders({
      query: { tokenAddress },
    });
    setHolders(result);
  };
  useEffect(() => {
    getRankingsBoundingCurve();
  }, [tokenAddress]);

  return holders;
};
