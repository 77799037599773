import { pumpTradeModel } from '@models/pump-trade.model';
import { useModel } from 'foca';
import { isEqual, throttle } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useRefreshFlag } from './use-refresh';

const fetchTradeHistory = throttle(pumpTradeModel.fetchTradeHistory, 10, {
  leading: true,
  trailing: false,
});
export const usePumpTradeHistory = (tokenAddress: string, page: number) => {
  const [_, setPre] = useState<{ tokenAddress: string; page: number }>();
  const [loading, setLading] = useState(false);
  const { refresh } = useRefreshFlag();
  const tradeHistories = useModel(pumpTradeModel, (state) => state.tradeHistory[tokenAddress]);

  const getHistory = async () => {
    const data = { tokenAddress, page };
    setPre((pre) => {
      if (!isEqual(pre, data)) {
        setLading(true);
      }
      return pre;
    });

    if (tokenAddress) {
      await fetchTradeHistory(tokenAddress, page);
    }
    setPre(data);
    setLading(false);
  };

  useEffect(() => {
    getHistory();
  }, [page, refresh, tokenAddress]);

  return { loading, tradeHistories };
};
