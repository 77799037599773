import { config } from '@config';
import { type TurbosCurrentRpcNode } from '@models/global-setting.model';
import { TurbosSdk } from 'turbos-clmm-sdk';

const cacheRpc = localStorage.getItem('TURBOS_CURRENT_RPC');
let url = cacheRpc ? (JSON.parse(cacheRpc) as TurbosCurrentRpcNode).rpc : undefined;
url = url ? url : config.fullnode ? config.fullnode : undefined;

export const turbosSdk = new TurbosSdk(config.sui_network, url ? { url: url } : undefined);
export const current_turbos_rpc = url;
