import Lottie, { type LottieRefCurrentProps } from 'lottie-react';
import React, {
  useContext,
  type FC,
  type ReactNode,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import lottieData from '@assets/lottie/refresh.json';
import http from 'src/services/http';

const RefreshContext = React.createContext<{
  refresh: number;
  manual: number;
  notifyRefresh: (delayMs?: number) => void;
  // @ts-expect-error
}>(null);

export const useRefreshFlag = () => {
  return useContext(RefreshContext);
};

export const useClickRefreshIcon = (onReload: (reLoading: boolean) => void) => {
  const { notifyRefresh } = useRefreshFlag();
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const handleClick = useCallback(() => {
    lottieRef.current?.setSpeed(1);
    lottieRef.current?.goToAndPlay(450, true);
    notifyRefresh();
    onReload(true);
  }, [notifyRefresh]);

  const handleAnimationComplete = useCallback(() => {
    lottieRef.current?.stop();
  }, []);

  return (
    <a onClick={handleClick}>
      <Lottie
        lottieRef={lottieRef}
        autoplay={false}
        animationData={lottieData}
        loop={false}
        onComplete={handleAnimationComplete}
      />
    </a>
  );
};

export const useRefreshIcon = () => {
  const { refresh, notifyRefresh } = useRefreshFlag();
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const handleClick = useCallback(() => {
    notifyRefresh();
  }, [notifyRefresh]);

  const handleAnimationComplete = useCallback(() => {
    lottieRef.current?.stop();
  }, []);

  useEffect(() => {
    lottieRef.current?.stop();
    lottieRef.current?.setSpeed(2.5);
    lottieRef.current?.play();
  }, [refresh]);

  return (
    <a onClick={handleClick}>
      <Lottie
        lottieRef={lottieRef}
        autoplay={false}
        animationData={lottieData}
        loop={false}
        onComplete={handleAnimationComplete}
      />
    </a>
  );
};

export const RefreshProvider: FC<{ children: ReactNode; timeout?: number }> = ({ children }) => {
  const [flag, setFlag] = useState(0);
  const [manual, setManual] = useState(0);

  const update = useCallback((delayMs: number = 0) => {
    setTimeout(() => {
      http.clearCache();
      setFlag((prev) => Math.abs(prev - 1));
      setManual((prev) => Math.abs(prev - 1));
    }, delayMs);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      http.clearCache();
      setFlag((prev) => Math.abs(prev - 1));
    }, 20_000);
    return () => clearInterval(timer);
  }, [flag]);

  return (
    <RefreshContext.Provider value={{ refresh: flag, manual, notifyRefresh: update }}>
      {children}
    </RefreshContext.Provider>
  );
};
