import { pumpSdk } from '@libs/pump';
import Input from '@ui/input';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { filterPositiveNumber } from '@utils/filter-positive-number';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './sell.module.scss';

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
  atob: boolean;
  isExact: boolean;
  amount: string | undefined;
  onChangeAmount(amount: string): void;
  balance: string;
  tipText: string;
  receiveAmount: string;
}
const Sell: FC<OwnerProps> = ({
  isExact,
  pool,
  onChangeAmount,
  amount,
  balance,
  tipText,
  receiveAmount,
}) => {
  const handleAmount = (ratio: number) => {
    onChangeAmount(new Decimal(balance).mul(ratio).toString());
  };
  return (
    <>
      <Input
        placeholder="Enter the amount"
        wrapperClassName={`${styles.input} ${isExact ? styles.input_balance : ''}`}
        value={amount}
        onChangeText={(value) => {
          const result = filterPositiveNumber(value);
          onChangeAmount(result.str);
        }}
        suffix={
          <>
            <div className={styles.coin}>
              <span>{pool.symbol}</span>
              <img src={pool.token_metadata.iconUrl} />
            </div>
            {isExact && (
              <div className={styles.balance}>
                Balance: <span>{bigNumberToReadable(balance, 0, true)}</span>
              </div>
            )}
          </>
        }
      />

      {!!tipText && <div className={styles.error_tip}>{tipText}</div>}

      <ul className={styles.sui_tab}>
        <li onClick={() => handleAmount(0.25)}>25%</li>
        <li onClick={() => handleAmount(0.5)}>50%</li>
        <li onClick={() => handleAmount(0.75)}>75%</li>
        <li onClick={() => handleAmount(1)}>100%</li>
      </ul>

      <div className={styles.line}>
        <p>You will receive:</p>
        <p>
          {bigNumberToReadable(receiveAmount, 0, true)} {pumpSdk.quote_symbol}
        </p>
      </div>
    </>
  );
};

export default Sell;
