import { NSFWOnOffModel } from '@models/on-off.model';
import { useModel } from 'foca';
import { type FC } from 'react';
import styles from './on-off.module.scss';

const OnOff: FC = () => {
  const onOff = useModel(NSFWOnOffModel, (state) => state['onoff']);

  return (
    <div className={styles.nsfw}>
      NSFW
      <div
        className={`${styles.on} ${!onOff ? styles.off : ''}`}
        onClick={() => NSFWOnOffModel.update('onoff', !onOff)}
      >
        {onOff && 'on'} <span></span>
        {!onOff && 'off'}
      </div>
    </div>
  );
};

export default OnOff;
