import { type FC } from 'react';
import styles from './index.module.scss';

const Footer: FC = () => {
  return (
    <div className={styles.footer}>
      Copyright © 2024 Turbos.Fun. All rights reserved
      <p>
        Turbos.Fun provides access to a decentralized platform for fair launch token creation and
        trading, with no warranties regarding the performance or value of any token. Use of the
        platform is at your own risk. Please assess your financial situation, conduct thorough
        research, and understand the risks of trading digital assets. Turbos.Fun is not liable for
        any losses or issues arising from your use of the platform.
      </p>
    </div>
  );
};

export default Footer;
