import PumpEmpty from '@components/pump-empty';
import { useAddress } from '@hooks/use-address';
import { usePumpOwned } from '@hooks/use-pump-owned';
import Loading from '@ui/loading';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const PumpOwned: FC = () => {
  const address = useAddress();
  const navigate = useNavigate();
  const { owned, loading } = usePumpOwned({
    owner: address || '',
  });

  const data = owned.data.filter((item) => item.balance && item.balance !== '0');
  return (
    <div>
      <div className={styles.title}>
        <div className={styles.left}>Token</div>
        <div className={styles.middle}>Quantity</div>
        <div className={styles.right}>Value</div>
      </div>
      {!loading &&
        data.map((item, index) => (
          <div
            className={styles.list}
            key={index}
            onClick={() => navigate(`/fun/${item.token_address}`)}
          >
            <div className={styles.coin}>
              <img src={item.token_metadata.iconUrl} />
              {item.token_metadata.symbol}
            </div>
            <div className={styles.middle}>{bigNumberToReadable(item.balance, 0)}</div>
            <div className={styles.right}>{bigNumberToReadable(item.volume_sui, 0)} SUI</div>
          </div>
        ))}
      {loading && (
        <div className={styles.loading}>
          <Loading size={48} />
        </div>
      )}
      {!loading && data.length < 1 && <PumpEmpty />}
    </div>
  );
};

export default PumpOwned;
