import { globalSettingModel } from '@models/global-setting.model';
import { useModel } from 'foca';
import { type FC } from 'react';
import styles from './setting-rpc.module.scss';
import rpcIcon from '@assets/images/icons/rpc.svg';
import arrowDownIcon from '@assets/images/icons/arrow-down.svg';

interface OwnProps {
  toggleSelecting(): void;
}

const SettingRpc: FC<OwnProps> = ({ toggleSelecting }) => {
  const rpcKey = useModel(globalSettingModel, (state) => state.rpcKey);
  return (
    <>
      <p className={styles.preferred}>
        <img src={rpcIcon} data-icon />
        RPC Node
      </p>
      <div className={styles.selected} onClick={toggleSelecting}>
        {rpcKey.replace(/\_/g, ' ')}
        <img src={arrowDownIcon} />
      </div>
    </>
  );
};

export default SettingRpc;
