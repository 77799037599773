import styles from './explorer-setting.module.scss';
import { type FC } from 'react';
import settingIcon from '@assets/images/icons/setting.svg';
import Tooltip from '@ui/tooltip';
import { useToggle } from 'react-use';
import SettingPreferredExplorer from './setting-preferred-explorer';
import SettingPreferredExplorerContent from './setting-preferred-explorer-content';
import SettingRpc from './setting-rpc';
import SettingRpcContent from './setting-rpc-content';

export const ExplorerSetting: FC = () => {
  const [selecting, toggleSelecting] = useToggle(false);
  const [rpcSelecting, toggleRpcSelecting] = useToggle(false);

  const $settings = (
    <div className={styles.settings}>
      <p className={styles.title}>Settings</p>
      <div className={styles.divider}></div>
      <SettingPreferredExplorer toggleSelecting={toggleSelecting} />
      <div className={styles.divider}></div>
      <SettingRpc toggleSelecting={toggleRpcSelecting} />
    </div>
  );

  const $selecting = (
    <div className={styles.settings}>
      {selecting ? <SettingPreferredExplorerContent toggleSelecting={toggleSelecting} /> : null}
      {rpcSelecting ? <SettingRpcContent toggleSelecting={toggleRpcSelecting} /> : null}
    </div>
  );

  return (
    <Tooltip overlay={selecting || rpcSelecting ? $selecting : $settings} placement="bottomRight">
      <div className={styles.wrapper}>
        <img src={settingIcon} data-icon />
      </div>
    </Tooltip>
  );
};
