import { type FC, memo } from 'react';
import styles from './index.module.scss';
import defaultCoinIcon from '@assets/images/icons/coin-default.png';

interface OwnProps {
  src: string | undefined;
  className?: string;
}

const CoinLogo: FC<OwnProps> = ({ src, className = '' }) => {
  return <img src={src || defaultCoinIcon} className={`${styles.coin} ${className}`} data-icon />;
};

export default memo(CoinLogo);
