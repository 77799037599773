import { type FC } from 'react';
import { Decimal } from 'turbos-clmm-sdk';

interface OwnerProps {
  value: string | number | Decimal;
}

const FormatZeroDecimal: FC<OwnerProps> = ({ value }) => {
  const num = Number(value);
  if (!num || Number.isNaN(num) || new Decimal(num).gte(0.01)) {
    return <>{value}</>;
  }

  const res = value.toString().replace(/-?0\./, '').match(/^0+/);
  const len = res![0]!.length;
  const _val = new Decimal(value)
    .mul(10 ** len)
    .toString()
    .replace(/-?0\./, '');
  return (
    <>
      0.0<sub>{res![0]!.length}</sub>
      {_val}
    </>
  );
};

export default FormatZeroDecimal;
