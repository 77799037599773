import Button from '@ui/button';
import { useState, type FC } from 'react';
import styles from './index.module.scss';
import { pumpSdk } from '@libs/pump';
import { useAddress } from '@hooks/use-address';
import { useTransaction } from '@hooks/use-transaction';
import Setting from '../select-slippage';
import { OpenapiClientPump } from 'foca-openapi';
import Buy from './buy';
import Sell from './sell';
import { Decimal } from 'turbos-clmm-sdk';
import { useBalance } from '@hooks/use-balances';
import { useDebounce } from 'react-use';
import { usePumpSwapRoute } from '@hooks/use-pump-swap-route';
import { turbosSdk } from '@libs/turbos-sdk';
import Loading from '@ui/loading';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { Link } from 'react-router-dom';
import { config } from '@config';
import { walletConnectModel } from '@models/wallet-connect.model';
import { useModel } from 'foca';
import { slippageModel } from '@models/slippage';

interface TypeItem {
  label: 'buy' | 'sell';
  name: string;
}

const types: TypeItem[] = [
  {
    label: 'buy',
    name: 'Buy',
  },
  {
    label: 'sell',
    name: 'Sell',
  },
];

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const PumpSwap: FC<OwnerProps> = ({ pool }) => {
  const address = useAddress();
  const transact = useTransaction();
  const [isExact, setIsExact] = useState(true);
  const [type, setType] = useState<TypeItem['label']>('buy');
  const [amount, setAmount] = useState<undefined | string>();
  const slippage = useModel(slippageModel, (state) => state.slippage);

  const [finalAmount, setFinalAmount] = useState<undefined | string>();

  const isBuy = type === 'buy';
  const bigBalance = useBalance(isBuy ? pumpSdk.quote_address : pool.token_address);

  const decimalsA = pool.token_metadata.decimals;
  const decimalsB = pumpSdk.quote_decimals;

  useDebounce(
    () => {
      setFinalAmount(amount);
    },
    100,
    [amount],
  );

  const { amountIn, amountOut, bestRoute, fetchingBestRoute, insufficientLiquidity } =
    usePumpSwapRoute(
      pool.clmm_pool_id!,
      new Decimal(finalAmount || '0')
        .mul(10 ** (isBuy && isExact ? decimalsB : decimalsA))
        .toFixed(0),
      isExact,
      !isBuy,
    );
  const _receiveAmount = isExact ? amountOut : amountIn;
  const receiveAmount = new Decimal(
    _receiveAmount && new Decimal(_receiveAmount).gt(0) ? _receiveAmount : '0',
  )
    .div(10 ** (isExact && isBuy ? decimalsA : decimalsB))
    .mul(
      isExact ? new Decimal(100).sub(slippage).div(100) : new Decimal(100).add(slippage).div(100),
    )
    .toString();

  const balance = new Decimal(bigBalance || 0)
    .div(10 ** (isBuy ? decimalsB : decimalsA))
    .toString();

  const handleTrade = async () => {
    if (
      !pool.token_address ||
      !address ||
      !amount ||
      amount === '0' ||
      !bestRoute ||
      !amountIn ||
      !amountOut
    )
      return;

    const symbolA = isBuy ? pumpSdk.quote_symbol : pool.symbol;
    const symbolB = !isBuy ? pumpSdk.quote_symbol : pool.symbol;

    const amountA = isExact ? amount : receiveAmount;
    const amountB = !isExact ? amount : receiveAmount;

    const ok = await transact(
      `Traded ${bigNumberToReadable(amountA, 0)} ${symbolA} for ${bigNumberToReadable(
        amountB,
        0,
      )} ${symbolB}`,
      async () => {
        return turbosSdk.trade.swap({
          routes: bestRoute.map(({ swapResult, nextTickIndex }) => ({
            pool: swapResult.pool,
            a2b: swapResult.a_to_b,
            nextTickIndex,
          })),
          coinTypeA: isBuy ? pumpSdk.quote_address : pool.token_address,
          coinTypeB: !isBuy ? pumpSdk.quote_address : pool.token_address,
          address,
          amountA: amountIn,
          amountB: amountOut,
          amountSpecifiedIsInput: isExact,
          slippage,
        });
      },
    );
    ok.status && setAmount('');
  };

  let tipText = '';
  if (
    (isExact && new Decimal(amount || '0').gt(balance)) ||
    (!isExact && new Decimal(receiveAmount).gt(balance))
  ) {
    tipText = 'Insufficient balance';
  } else if (bestRoute && insufficientLiquidity) {
    tipText = 'Insufficient liquidity';
  }

  const disabled = !!(
    address &&
    (!amount ||
      !!tipText ||
      receiveAmount === '0' ||
      fetchingBestRoute ||
      !bestRoute ||
      !bestRoute.length)
  );

  return (
    <div className={styles.buy_sell}>
      <div className={styles.dex}>Trade on Turbos DEX</div>
      <div className={styles.tabs}>
        {types.map((item) => (
          <div
            key={item.label}
            className={item.label === type ? styles.active : ''}
            onClick={() => {
              setType(item.label);
              setIsExact(true);
              setAmount('');
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className={styles.slippage_switch}>
        <Setting />
        {isBuy && (
          <div
            className={styles.switch}
            onClick={() => {
              setIsExact(!isExact);
              setAmount('');
            }}
          >
            switch to {(isExact && isBuy) || (!isExact && !isBuy) ? pool.symbol : 'SUI'}
          </div>
        )}
      </div>

      {isBuy ? (
        <Buy
          pool={pool}
          amount={amount}
          onChangeAmount={setAmount}
          atob={isBuy}
          isExact={isExact}
          balance={balance}
          tipText={tipText}
          receiveAmount={receiveAmount}
        />
      ) : (
        <Sell
          pool={pool}
          amount={amount}
          onChangeAmount={setAmount}
          atob={isBuy}
          isExact={isExact}
          balance={balance}
          tipText={tipText}
          receiveAmount={receiveAmount}
        />
      )}

      <Button
        fullWidth
        type="highlight"
        className={`${styles.button} ${disabled ? styles.disabled : ''}`}
        onClick={!address ? walletConnectModel.toggleDialog : handleTrade}
        disabled={disabled}
      >
        {!address ? 'Connect Wallet' : fetchingBestRoute ? <Loading /> : 'Trade'}
      </Button>

      <div className={styles.copyright}>
        <Link to={`${config.url}/#/pools/${pool.clmm_pool_id}/add-liquidity`} target="_blank">
          The pool has been migrated to Turbos DEX
        </Link>
      </div>
    </div>
  );
};

export default PumpSwap;
