import Search from '@ui/input/search';
import { type FC, memo, useState } from 'react';
import styles from './index.module.scss';
import Dropdown from '@ui/dropdown';
import arrowDown from '@assets/images/icons/arrow-down.svg';
import { poolFetchOrders } from '@constants/pool-fetch-condition';
import { useToggle } from 'react-use';
import reloadIcon from '@assets/images/pump/reload.svg';
import sortDescIcon from '@assets/images/pump/sort-desc.svg';
import sortAscIcon from '@assets/images/pump/sort-asc.svg';
import listedIcon from '@assets/images/pump/listed.svg';
import listedActiveIcon from '@assets/images/pump/listed-active.svg';
import launchingIcon from '@assets/images/pump/launching.svg';
import launchingActiveIcon from '@assets/images/pump/launching-active.svg';
import { type SortParams } from '@models/pump-pool.model';
import OnOff from './on-off';

interface OwnProps {
  onChangePage(value: number): void;
  onSearch(name: string): void;
  sort: SortParams;
  onSortChange(value: SortParams): void;
  completed: boolean | undefined;
  onCompletedChange(value: boolean | undefined): void;
  onChangeReload(value: number): void;
  direction: 'desc' | 'asc';
  onChangeDirection(value: 'desc' | 'asc'): void;
}

const PumpSearch: FC<OwnProps> = (props) => {
  const {
    onSearch,
    sort,
    onSortChange,
    completed,
    onCompletedChange,
    onChangePage,
    onChangeReload,
    direction,
    onChangeDirection,
  } = props;
  const [openOrderBy, toggleOrderBy] = useToggle(false);
  const [reload, setReload] = useState(false);

  const launchTimeData = poolFetchOrders.find((item) => item.value === sort);

  return (
    <div className={styles.wrapper}>
      <Search
        placeholder="Search for tokens"
        onChangeText={onSearch}
        wrapperClassName={styles.input}
      />
      <div className={styles.right}>
        <div className={styles.check}>
          <div
            className={`${styles.check_left} ${
              !completed && completed !== undefined ? styles.check_active : ''
            }`}
            onClick={() => {
              onCompletedChange(false);
              onChangePage(1);
              onSortChange('created_at');
            }}
          >
            <img
              src={!completed && completed !== undefined ? launchingActiveIcon : launchingIcon}
            />
            <span>Racing on the Bonding Curve</span>
          </div>
          <div className={styles.check_right_mobile}>
            <div
              className={`${styles.check_right} ${
                completed && completed !== undefined ? styles.check_active : ''
              }`}
              onClick={() => {
                onCompletedChange(true);
                onChangePage(1);
                onSortChange('market_cap_sui');
              }}
            >
              <img src={completed && completed !== undefined ? listedActiveIcon : listedIcon} />
              <span>Listed on Turbos DEX</span>
            </div>
            <div className={styles.onoff_mobile}>
              <OnOff />
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <Dropdown
            onVisibleChange={toggleOrderBy}
            overlayClassName={styles.dropdown}
            overlay={
              <ul>
                {poolFetchOrders.map((item) => {
                  return (
                    <li
                      key={item.value}
                      onClick={() => {
                        onSortChange(item.value);
                        toggleOrderBy();
                      }}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            }
          >
            <div className={`${styles.select} ${openOrderBy ? styles.active : ''}`}>
              <span>{sort ? `${launchTimeData?.name}` : 'Creation Time'}</span>
              <img src={arrowDown} />
            </div>
          </Dropdown>
          <div className={styles.dropdown_wrapper}>
            <div
              className={`${styles.sort}`}
              onClick={() => onChangeDirection(direction === 'desc' ? 'asc' : 'desc')}
            >
              <img src={direction === 'asc' ? sortAscIcon : sortDescIcon} />
            </div>
            <div className={styles.onoff_pc}>
              <OnOff />
            </div>
            <div
              className={`${styles.reload} ${reload ? styles.reload_active : ''}`}
              onClick={() => {
                setReload(true);
                onChangeReload(Date.now());

                const timer = setTimeout(() => {
                  setReload(false);
                  clearTimeout(timer);
                }, 1000);
              }}
            >
              <img src={reloadIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PumpSearch);
