import { parseGlobIcon } from '@utils/parse-glob-icon';
import { type FC, memo } from 'react';
import styles from './links.module.scss';

const Links: FC = () => {
  return (
    <div className={styles.links}>
      <a href="https://x.com/Turbos_finance" target="_blank" rel="noreferrer">
        <img src={getSocialIcon('twitter')} />
      </a>
      <a href="https://t.me/TurbosFinance" target="_blank" rel="noreferrer">
        <img src={getSocialIcon('telegram')} />
      </a>
      <a href="https://medium.com/@turbos" target="_blank" rel="noreferrer">
        <img src={getSocialIcon('medium')} />
      </a>
      <a href="https://github.com/turbos-finance" target="_blank" rel="noreferrer">
        <img src={getSocialIcon('github')} />
      </a>
      <a href="https://turbos.gitbook.io/turbos/" target="_blank" rel="noreferrer">
        <img src={getSocialIcon('gitbook')} />
      </a>
      <a href="https://discord.com/invite/xFdDdDzA8W" target="_blank" rel="noreferrer">
        <img src={getSocialIcon('discord')} />
      </a>
    </div>
  );
};

const getSocialIcon = parseGlobIcon(
  import.meta.glob('../../assets/images/socials/*', {
    eager: true,
    import: 'default',
  }),
);

export default memo(Links);
