import { pumpSdk } from '@libs/pump';
import Input from '@ui/input';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { filterPositiveNumber } from '@utils/filter-positive-number';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './buy.module.scss';

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
  atob: boolean;
  isExact: boolean;
  amount: string | undefined;
  onChangeAmount(amount: string): void;
  balance: string;
  tipText: string;
  receiveAmount: string;
}

const Buy: FC<OwnerProps> = ({
  pool,
  atob,
  isExact,
  amount,
  onChangeAmount,
  balance,
  tipText,
  receiveAmount,
}) => {
  return (
    <>
      <Input
        placeholder="Enter the amount"
        wrapperClassName={`${styles.input} ${isExact ? styles.input_balance : ''}`}
        value={amount}
        onChangeText={(value) => {
          const result = filterPositiveNumber(value);
          onChangeAmount(result.str);
        }}
        suffix={
          <>
            <div className={styles.coin}>
              <span>{(isExact && atob) || (!isExact && !atob) ? 'SUI' : pool.symbol}</span>
              <img
                src={
                  (isExact && atob) || (!isExact && !atob)
                    ? pumpSdk.quote_logo_url
                    : pool.token_metadata.iconUrl
                }
              />
            </div>
            {isExact && (
              <div className={styles.balance}>
                Balance: <span>{bigNumberToReadable(balance, 0, true)}</span>
                <div onClick={() => onChangeAmount(new Decimal(balance).div(2).toString())}>
                  Half
                </div>
                <div onClick={() => onChangeAmount(balance)}>Max</div>
              </div>
            )}
          </>
        }
      />

      {!!tipText && <div className={styles.error_tip}>{tipText}</div>}

      {isExact && (
        <ul className={styles.sui_tab}>
          <li onClick={() => onChangeAmount('10')}>10 SUI</li>
          <li onClick={() => onChangeAmount('20')}>20 SUI</li>
          <li onClick={() => onChangeAmount('50')}>50 SUI</li>
          <li onClick={() => onChangeAmount('100')}>100 SUI</li>
        </ul>
      )}

      <div className={styles.line}>
        <p>You will {isExact ? 'receive' : 'pay'}:</p>
        <p>
          {bigNumberToReadable(receiveAmount, 0, true)}{' '}
          {isExact ? pool.symbol : pumpSdk.quote_symbol}
        </p>
      </div>

      {!isExact && (
        <div className={styles.line}>
          <p>Balance:</p>
          <p>
            {bigNumberToReadable(balance, 0, true)} {atob ? 'SUI' : pool.symbol}
          </p>
        </div>
      )}
    </>
  );
};

export default Buy;
