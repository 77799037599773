import { useState, type FC } from 'react';
import styles from './index.module.scss';
import pageIcon from '@assets/images/pump/page.svg';

interface OwnProps {
  total?: number;
  limit?: number;
  page?: number;
  onChange?(value: number): void;
}

const Pagination: FC<OwnProps> = (props) => {
  const { total = 1, limit = 10, onChange, page } = props;

  const [value, setValue] = useState(page || 1);

  const max = Math.ceil(total / limit);
  const handleChange = (value: number) => {
    if (value > max || value < 1) {
      return;
    }
    setValue(value);
    if (onChange) onChange(value);
  };

  return (
    <div className={styles.page}>
      <img
        src={pageIcon}
        className={`${styles.left} ${value <= 1 ? styles.disabled : ''}`}
        onClick={() => handleChange(value - 1)}
      />
      <span>
        Page {page || value} of {max || 1}
      </span>
      <img
        src={pageIcon}
        className={`${styles.right} ${value >= max ? styles.disabled : ''}`}
        onClick={() => handleChange(value + 1)}
      />
    </div>
  );
};

export default Pagination;
