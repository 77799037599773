import Pagination from '@components/pagination';
import { usePumpThreadList } from '@hooks/use-pump-comments';
import Textarea from '@ui/input/textarea';
import Loading from '@ui/loading';
import { OpenapiClientPump } from 'foca-openapi';
import { useState, type FC } from 'react';
import styles from './index.module.scss';
import List from './list';
import Submit from './submit';

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const Comments: FC<OwnerProps> = ({ pool }) => {
  const [text, setText] = useState('');
  const [page, setPage] = useState(1);
  const { threadList, loading: threadListLoading } = usePumpThreadList(pool.token_address, page);
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.comments}>
        <Textarea
          maxLength={256}
          rows={4}
          onChangeText={setText}
          value={text}
          placeholder="Write your comments"
          wrapperClassName={styles.wrapper_textarea}
          suffix={
            <Submit pool={pool} onChangeText={setText} text={text} onChangeLoading={setLoading} />
          }
        />
        {loading && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>

      {threadListLoading && (
        <div className={styles.threadListLoading}>
          <Loading />
        </div>
      )}
      {threadList && !threadListLoading && (
        <>
          <ul className={styles.items}>
            {threadList.data.map((item) => (
              <List item={item} key={item.id} />
            ))}
          </ul>
          {threadList.total > threadList.pageSize && (
            <Pagination
              onChange={setPage}
              page={page}
              total={threadList.total}
              limit={threadList.pageSize}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Comments;
