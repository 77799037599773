import { turbosSdk } from 'src/services/turbos-sdk';
import type { CoinBalance } from '@mysten/sui/client';
import { defineModel } from 'foca';

const initialState: Record<string, CoinBalance[]> = {};

export const balanceModel = defineModel('balance', {
  initialState,
  methods: {
    async getBalances(account: string) {
      const result = await turbosSdk.provider.getAllBalances({ owner: account });
      result.forEach((balance) => {
        balance.coinType = turbosSdk.coin.formatCoinType(balance.coinType, true);
      });
      this.setState({
        [account]: result,
      });
      return result;
    },
  },
});
