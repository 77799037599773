import { type FC } from 'react';
import backIcon from '@assets/images/icons/back.svg';
import styles from './setting-preferred-explorer-content.module.scss';
import { config } from '@config';
import { globalSettingModel } from '@models/global-setting.model';
import { startCase } from 'lodash-es';
import { useModel } from 'foca';

const explorerKeys = Object.keys(config.explorer_url) as (keyof typeof config.explorer_url)[];

interface OwnProps {
  toggleSelecting(): void;
}

const SettingPreferredExplorerContent: FC<OwnProps> = ({ toggleSelecting }) => {
  const explorerKey = useModel(globalSettingModel, (state) => state.explorerKey);
  return (
    <>
      <div className={styles.header}>
        <img src={backIcon} data-icon className={styles.back} onClick={toggleSelecting} />
        Preferred Explorer
      </div>
      <div className={styles.divider}></div>
      <ul className={styles.options}>
        {explorerKeys.map((key) => (
          <li
            key={key}
            onClick={() => {
              globalSettingModel.setExplorerKey(key);
              toggleSelecting();
            }}
            className={key === explorerKey ? styles.current : ''}
          >
            {startCase(key)}
          </li>
        ))}
      </ul>
    </>
  );
};

export default SettingPreferredExplorerContent;
