import { Decimal } from 'turbos-clmm-sdk';
import { numericFormatter } from 'react-number-format';

export const bigNumberToReadable = (
  bigAmount: Decimal.Value | undefined,
  decimals: number | undefined,
  thousandSeparator: boolean = true,
  fixed?: number,
  isDynamic: boolean = true,
) => {
  if (bigAmount === undefined || decimals === undefined) return '0.00';

  const decimal = new Decimal(bigAmount).div(10 ** decimals);

  // if (decimal.isZero()) return '0.00';

  const absoluteValue = decimal.abs();
  const fixLength =
    fixed !== undefined
      ? fixed
      : absoluteValue.isZero()
      ? 2
      : absoluteValue.lessThan(0.0001)
      ? 9
      : absoluteValue.lessThan(1)
      ? 6
      : absoluteValue.lessThan(10)
      ? 5
      : 2;

  let value = (Math.floor(decimal.mul(10 ** fixLength).toNumber()) / 10 ** fixLength).toFixed(
    fixLength,
  );
  if (fixLength === 0) {
    value = decimal.toFixed(fixLength);
  } else if (fixLength >= 9 && isDynamic) {
    value = formatNumberWithDynamicPrecision(decimal);
  }
  return numericFormatter(value.replace(/\.0+$/, '').replace(/\.$/, ''), {
    thousandSeparator,
  });
};

function formatNumberWithDynamicPrecision(value: Decimal) {
  const decimal = value.toFixed();
  const decimalPart = decimal.split('.')[1] || '';

  if (!decimalPart) {
    return value.toFixed(5);
  }

  const match = decimalPart.match(/^0+/);
  const len = match ? match[0]?.length : 0;

  if (len) {
    return value.toFixed(len + 4);
    //.replace(match![0]!, `0{${len}}`)
  }

  return value.toFixed(5);
}
