import { OpenapiClientPump } from 'foca-openapi';
import { useState, type FC } from 'react';
import PreChart from './chart';
import styles from './index.module.scss';
import PostChart from './post-chart';

const tabs = [
  {
    label: 'Pre-bonding Chart',
    value: 'pre',
  },
  {
    label: 'Post-bonding Chart',
    value: 'post',
  },
];

interface OwnProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const PumpChart: FC<OwnProps> = ({ pool }) => {
  const [tab, setTabs] = useState(pool.clmm_pool_id ? 'post' : 'pre');

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.title}>
          <img src={pool.uri} />
          <span>
            {pool.name} (${pool.symbol})
          </span>
        </div>
        {pool.is_completed && (
          <div className={styles.chart}>
            {tabs.map((item) => (
              <div
                key={item.value}
                onClick={() => setTabs(item.value)}
                className={item.value === tab ? styles.active : ''}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>

      {tab === 'pre' && <PreChart pool={pool} />}
      {tab === 'post' && <PostChart pool={pool} />}
    </div>
  );
};

export default PumpChart;
