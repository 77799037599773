import { globalSettingModel } from '@models/global-setting.model';
import { useResolveSuiNSName } from '@mysten/dapp-kit';
import { formatAddress } from '@mysten/sui/utils';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { timeDifference } from '@utils/time-difference';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './tr.module.scss';

interface OwnerProps {
  item: OpenapiClientPump.GetTradeHistoryResponse['data'][number];
}

const Tr: FC<OwnerProps> = ({ item }) => {
  const suiNames = useResolveSuiNSName(item.sender);
  return (
    <tr key={item.tx_digest} className={styles.tr}>
      <td align="left" className={styles.blue}>
        <Link to={globalSettingModel.explorerUrlForAccount(item.sender)} target="_blank">
          {suiNames.data ? suiNames.data : formatAddress(item.sender)}
        </Link>
      </td>
      <td align="center" className={item.is_buy ? styles.green : styles.red}>
        {item.is_buy ? 'Buy' : 'Sell'}
      </td>
      <td align="center">{item.sui_amount}</td>
      <td align="center">{bigNumberToReadable(item.token_amount, 0, true)}</td>
      <td align="center">{timeDifference(item.timestamp)} ago</td>
      <td align="right" className={styles.blue}>
        <Link to={globalSettingModel.explorerUrlForTransaction(item.tx_digest)} target="_blank">
          {formatAddress(item.tx_digest)}
        </Link>
      </td>
    </tr>
  );
};

export default Tr;
