import {
  memo,
  type FC,
  type InputHTMLAttributes,
  type ReactNode,
  useRef,
  useCallback,
  useState,
  type FocusEventHandler,
  type ChangeEventHandler,
} from 'react';
import styles from './index.module.scss';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  suffix?: ReactNode;
  prefix?: ReactNode;
  wrapperClassName?: string;
  onChangeText?(value: string): void;
  align?: 'left' | 'center' | 'right';
}

const Input: FC<InputProps> = ({
  prefix,
  suffix,
  wrapperClassName,
  onChangeText,
  align = 'left',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      rest.onChange?.(e);
      onChangeText?.(e.target.value);
    },
    [rest.onChange, onChangeText],
  );

  const handleFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleSetFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsFocus(true);
      rest.onFocus?.(e);
    },
    [rest.onBlur],
  );

  const handleSetBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsFocus(false);
      rest.onBlur?.(e);
    },
    [rest.onBlur],
  );

  return (
    <div
      className={`${styles.wrapper} ${suffix ? styles.has_suffix : ''} ${
        isFocus ? styles.focus : ''
      } ${wrapperClassName}`}
      onClick={handleFocus}
    >
      {prefix}
      <input
        {...rest}
        className={`${styles.input} ${styles['align_' + align]} ${rest.className}`}
        ref={inputRef}
        onFocus={handleSetFocus}
        onBlur={handleSetBlur}
        onChange={handleChange}
      />
      {suffix}
    </div>
  );
};

export default memo(Input);
