import dayjs from 'dayjs';

export const timeDifference = (date: Date | number | string, len: 1 | 2 = 2) => {
  const now = dayjs();
  const inputDate = dayjs(date);
  const diffInSeconds = now.diff(inputDate, 'second'); // 获取秒差

  const days = Math.floor(diffInSeconds / (24 * 3600));
  const hours = Math.floor((diffInSeconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = diffInSeconds % 60;

  if (len === 1) {
    if (days) {
      return `${days} days`;
    } else if (hours) {
      return `${hours} hours`;
    } else if (minutes) {
      return `${minutes} minutes`;
    }
    return `${seconds} seconds`;
  }

  if (days) {
    return `${days}d ${hours}h`;
  } else if (hours) {
    return `${hours}h ${minutes}m`;
  } else if (minutes) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
};
