import ContactType from '@components/contact-type';
import { formatAddress } from '@mysten/sui/utils';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';
import styles from './list.module.scss';
import copyIcon from '@assets/images/pump/copy.svg';
import { Decimal } from 'turbos-clmm-sdk';
import { pumpSdk } from '@libs/pump';
import { formatContract } from '@utils/format-contract';
import { formatDigitUnit } from '@utils/format-digit-unit';
import { useResolveSuiNSName } from '@mysten/dapp-kit';

interface OwnProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}
const DataList: FC<OwnProps> = ({ pool }) => {
  const [, copy] = useCopyToClipboard();
  const navigate = useNavigate();

  let ratio = new Decimal(pool.real_sui_reserves)
    .div(pumpSdk.fair_launch_sui_amount)
    .mul(100)
    .toFixed(2);

  ratio = Number(ratio) > 100 ? '100' : ratio;
  const suiNames = useResolveSuiNSName(pool.created_by);
  return (
    <div
      key={pool.token_address}
      className={styles.list}
      onClick={() => navigate(`/fun/${pool.token_address}`)}
    >
      <div className={styles.top}>
        <div className={styles.title}>
          {pool.name} (${pool.symbol})
        </div>
        <ContactType
          website={pool.website}
          twitter={pool.twitter}
          telegram={pool.telegram}
          className={styles.contact}
        />
      </div>
      <div className={styles.contract}>
        CA: <span>{formatContract(pool.token_address)}</span>
        <img
          src={copyIcon}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            copy(pool.token_address);
            toast('Address is copied');
          }}
        />
      </div>

      <div className={styles.bottom}>
        <div className={styles.icon}>
          <img src={pool.uri} />
          {pool.is_completed && <div className={styles.completed}>Completed</div>}
        </div>

        <div className={styles.content}>
          <div className={styles.description}>{pool.description}</div>
          <div className={styles.line}>
            <p>Created by:</p>
            <p className={styles.address}>
              {suiNames.data
                ? suiNames.data
                : pool.created_by
                ? formatAddress(pool.created_by)
                : ''}
            </p>
          </div>
          <div className={styles.line}>
            <p>{pool.is_completed ? 'Market Cap:' : 'Bonding Curve Progress:'}</p>
            <p className={styles.liquidity}>
              {pool.is_completed ? formatDigitUnit(pool.market_cap_usd) : `${ratio}%`}
            </p>
          </div>
          <div className={styles.percent}>
            <div
              className={styles.width}
              style={{ width: `${pool.is_completed ? '100' : ratio}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataList;
