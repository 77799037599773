import { useEffect, useRef, useState, type FC } from 'react';
import styles from './chart.module.scss';
import {
  createChart,
  type IChartApi,
  type ISeriesApi,
  type Time,
  type UTCTimestamp,
} from 'lightweight-charts';
import Tooltip from '@ui/tooltip';
import { useModel } from 'foca';
import upIcon from '@assets/images/icons/limit-up.svg';
import downIcon from '@assets/images/icons/limit-down.svg';
import TimeSelect from './time-select';
import { dateRanges } from '@constants/date-range';
import { OpenapiClientPump } from 'foca-openapi';
import { pumpTradeModel } from '@models/pump-trade.model';
import Loading from '@ui/loading';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import FormatZeroDecimal from '@components/format-zero-decimal';
import { Decimal } from 'turbos-clmm-sdk';

interface OwnProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const PreChart: FC<OwnProps> = ({ pool }) => {
  const maskElement = useRef<HTMLDivElement>(null);
  const chartElement = useRef<HTMLDivElement>(null);
  const [cursorMoveToTime, setCursorMoveToTime] = useState<number>();
  const [currentDateRange, setCurrentDateRange] = useState(dateRanges[3]!);
  const [visible, setVisible] = useState(false);
  const priceKey = `${pool.token_address}:${currentDateRange.label}`;
  const chart = useRef<IChartApi>();
  const series = useRef<ISeriesApi<'Candlestick', Time>>();
  const prices = useModel(pumpTradeModel, (state) => state.ochl[priceKey]);
  const infoBarRecord = useModel(pumpTradeModel, (state) => {
    const prices = state.ochl[priceKey] || [];
    return prices.find((item) => item.timestamp === cursorMoveToTime) || prices[prices.length - 1];
  });
  // chart cannot adjust width itself when window resize
  useEffect(() => {
    const updateChartSize = () => {
      if (!maskElement.current || !chartElement.current) return;
      const style = window.getComputedStyle(maskElement.current);
      chartElement.current.style.width = style.width;
      chartElement.current.style.height = style.height;
    };
    updateChartSize();
    const observer = new ResizeObserver(updateChartSize);
    observer.observe(maskElement.current!);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    chart.current = createChart(chartElement.current!, {
      autoSize: true,
      localization: { locale: 'en-US' },
      layout: {
        textColor: 'white',
        background: {
          color: 'transparent',
        },
      },
      rightPriceScale: {
        borderColor: 'rgba(255,255,255,0.3)',
      },
      grid: {
        vertLines: {
          color: 'rgba(255,255,255,0.05)',
          style: 0,
        },
        horzLines: {
          color: 'rgba(255,255,255,0.05)',
          style: 0,
        },
      },
      timeScale: {
        timeVisible: true,
        borderColor: 'rgba(255,255,255,0.3)',
      },
    });

    chart.current.subscribeCrosshairMove(({ time }) => {
      setCursorMoveToTime(time as number | undefined);
    });

    series.current = chart.current.addCandlestickSeries({
      upColor: '#26a69a',
      wickUpColor: '#26a69a',
      downColor: '#ef5350',
      wickDownColor: '#ef5350',
      borderVisible: false,
      priceFormat: {
        type: 'custom',
        formatter: (price: number) => {
          const newPrice = bigNumberToReadable(price, 0);
          if (price >= 0.01) {
            return newPrice;
          }
          const res = newPrice.toString().replace(/-?0\./, '').match(/^0+/);
          if (!res) {
            return newPrice;
          }
          const len = res[0]!.length;
          const _val = new Decimal(newPrice)
            .mul(10 ** len)
            .toString()
            .replace(/-?0\./, '');
          return `0.0${String.fromCharCode(8320 + len)}${_val}`;
        },
        minMove: 0.0000000001,
      },
    });

    return () => {
      series.current = undefined;
      chart.current?.remove();
    };
  }, []);

  useEffect(() => {
    chart.current?.applyOptions({
      timeScale: {
        timeVisible: currentDateRange.timeVisible,
      },
    });
  }, [currentDateRange]);

  useEffect(() => {
    if (prices?.length) {
      series.current?.setData(
        prices.map(({ timestamp, ...rest }) => {
          return { ...rest, time: timestamp as UTCTimestamp };
        }),
      );
      chart.current?.timeScale().fitContent();
      chart.current?.timeScale().applyOptions({
        barSpacing: 8,
        rightOffset: 5,
        fixLeftEdge: false,
        lockVisibleTimeRangeOnResize: false,
      });
    }
  }, [currentDateRange, pool.token_address]);

  useEffect(() => {
    let destroyed = false;
    let timer: NodeJS.Timeout | undefined;

    pumpTradeModel
      .fetchOCHL({
        tokenAddress: pool.token_address,
        start: Date.now() - currentDateRange.value,
        step: currentDateRange.step,
        key: priceKey,
        operation: 'replace',
      })
      .then((result) => {
        if (destroyed) return;
        series.current?.setData(
          result.map(({ timestamp, ...rest }) => {
            return {
              ...rest,
              time: timestamp as UTCTimestamp,
            };
          }),
        );
        chart.current?.timeScale().applyOptions({
          barSpacing: 8,
          rightOffset: 5,
          fixLeftEdge: false,
          lockVisibleTimeRangeOnResize: false,
        });

        (function loop() {
          if (destroyed || pool.is_completed) return;
          timer = setTimeout(async () => {
            const result = await pumpTradeModel.fetchOCHL({
              tokenAddress: pool.token_address,
              start: Date.now(),
              step: currentDateRange.step,
              key: priceKey,
              operation: 'merge',
            });
            if (destroyed) return;
            result.forEach(({ timestamp, ...rest }) => {
              series.current?.update({
                ...rest,
                time: timestamp as UTCTimestamp,
              });
            });
            loop();
          }, 2_000);
        })();
      });

    return () => {
      destroyed = true;
      clearInterval(timer);
    };
  }, [currentDateRange, pool.token_address, pool.is_completed]);

  return (
    <div className={styles.chart_content}>
      <div className={styles.chart_wrapper}>
        {!prices && (
          <div className={styles.spinner}>
            <Loading size={48} />
          </div>
        )}
        <div className={styles.chart_top}>
          <ul className={styles.period}>
            {dateRanges
              .filter((range) => !range.category)
              .map((range) => {
                return (
                  <li
                    key={range.value}
                    onClick={() => setCurrentDateRange(range)}
                    className={range.label === currentDateRange.label ? styles.active : ''}
                  >
                    {range.label}
                  </li>
                );
              })}
          </ul>
          <Tooltip
            overlayClassName={styles.timerOverlay}
            trigger={['click']}
            placement="bottom"
            overlay={
              <TimeSelect
                dateRanges={dateRanges}
                changeDateRange={setCurrentDateRange}
                changeVisible={setVisible}
              />
            }
            visible={visible}
            onVisibleChange={setVisible}
          >
            <img src={visible ? upIcon : downIcon} className={styles.more} />
          </Tooltip>
        </div>

        <div
          className={`${styles.info_bar} ${
            !infoBarRecord || infoBarRecord.close > infoBarRecord.open ? styles.up : styles.down
          }`}
        >
          <div className={styles.prices}>
            <p>
              <span>O</span>
              <span>
                <FormatZeroDecimal
                  value={infoBarRecord ? bigNumberToReadable(infoBarRecord.open, 0) : '-'}
                />
              </span>
            </p>
            <p>
              <span>H</span>
              <span>
                <FormatZeroDecimal
                  value={infoBarRecord ? bigNumberToReadable(infoBarRecord.high, 0) : '-'}
                />
              </span>
            </p>
            <p>
              <span>L</span>
              <span>
                <FormatZeroDecimal
                  value={infoBarRecord ? bigNumberToReadable(infoBarRecord.low, 0) : '-'}
                />
              </span>
            </p>
            <p>
              <span>C</span>
              <span>
                <FormatZeroDecimal
                  value={infoBarRecord ? bigNumberToReadable(infoBarRecord.close, 0) : '-'}
                />
              </span>
            </p>
            {infoBarRecord && (
              <p>
                <span className={styles.indicator} />
                <span>
                  <FormatZeroDecimal
                    value={bigNumberToReadable(infoBarRecord.close - infoBarRecord.open, 0)}
                  />
                  (
                  <span className={styles.indicator} />
                  {(
                    ((infoBarRecord.close - infoBarRecord.open) / infoBarRecord.open) *
                    100
                  ).toFixed(2)}
                  %)
                </span>
              </p>
            )}
          </div>
        </div>

        <div className={styles.chart_mask} ref={maskElement} />
        <div className={styles.chart} ref={chartElement} />
      </div>
    </div>
  );
};

export default PreChart;
