import { defineModel } from 'foca';
import { type ReactElement } from 'react';

export enum TransactionStatus {
  confirm,
  success,
  fail,
}

const initialState: {
  visible: boolean;
  status?: TransactionStatus;
  digest?: string;
  description?: string | ReactElement;
  title?: string;
  successTip?: ReactElement;
  errorTip?: ReactElement;
  finishedTime?: string | number;
  confirm?: ReactElement;
} = {
  visible: false,
};

export const transactionModel = defineModel('transaction', {
  initialState,
  reducers: {
    toggleVisible(state) {
      state.visible = !state.visible;
      if (!state.visible) {
        state.description = undefined;
        state.digest = undefined;
        state.status = undefined;
        state.successTip = undefined;
        state.title = undefined;
        state.errorTip = undefined;
        state.finishedTime = undefined;
        state.confirm = undefined;
      }
    },
    update(
      state,
      options: {
        description?: string | ReactElement;
        digest?: string;
        status: TransactionStatus;
        title?: string;
        successTip?: ReactElement;
        errorTip?: ReactElement;
        finishedTime?: string | number;
        confirm?: ReactElement;
      },
    ) {
      Object.assign(state, options);
    },
  },
});
