import { pumpApi } from '@libs/openapi';
import { OpenapiClientPump } from 'foca-openapi';
import { useEffect, useState } from 'react';
import { useRefreshFlag } from './use-refresh';

export const usePumpKing = () => {
  const [king, setKing] = useState<OpenapiClientPump.GetPoolsKingResponse>();
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const { refresh } = useRefreshFlag();
  const getKing = async () => {
    !init && setLoading(true);
    try {
      const result = await pumpApi.pool.getPoolsKing();
      setKing(result);
      setInit(true);
    } catch (err) {
      setKing(undefined);
    }
    setLoading(false);
  };
  useEffect(() => {
    getKing();
  }, [refresh]);

  return { loading, data: king?.king };
};
