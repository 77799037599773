import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import twitterIcon from '@assets/images/pump/twitter.svg';
import telegramIcon from '@assets/images/pump/telegram.svg';
import websiteIcon from '@assets/images/pump/website.svg';
interface OwnProps {
  twitter?: string;
  website?: string;
  telegram?: string;
  className?: string;
}

const ContactType: FC<OwnProps> = ({ twitter, website, telegram, className }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  if (!twitter && !website && !telegram) return null;

  return (
    <div className={`${styles.contact} ${className}`} onClick={handleClick}>
      {twitter && (
        <Link to={twitter} target={'_blank'}>
          <img src={twitterIcon} />
        </Link>
      )}
      {website && (
        <Link to={website} target={'_blank'}>
          <img src={websiteIcon} />
        </Link>
      )}
      {telegram && (
        <Link to={telegram} target={'_blank'}>
          <img src={telegramIcon} />
        </Link>
      )}
    </div>
  );
};

export default ContactType;
