// import { walletConnectModel } from '@models/wallet-connect.model';
import { type FC } from 'react';
// import copyIcon from '@assets/images/icons/copy.svg';
// import transactionIcon from '@assets/images/icons/transaction-detail.svg';
// import changeWalletIcon from '@assets/images/icons/change-wallet.svg';
// import explorerIcon from '@assets/images/icons/explorer.svg';
import disconnectIcon from '@assets/images/icons/disconnect.svg';
// import switchIcon from '@assets/images/icons/swap.svg';
import portfolioIcon from '@assets/images/pump/portfolio.svg';
// import { useCopyToClipboard } from 'react-use';
import {
  useAccounts,
  useCurrentAccount,
  // useCurrentWallet,
  useDisconnectWallet,
  // useSwitchAccount,
} from '@mysten/dapp-kit';
// import { globalSettingModel } from '@models/global-setting.model';
import { Link } from 'react-router-dom';
import styles from './wallet-list.module.scss';
// import { toast } from 'react-toastify';
// import { useModel } from 'foca';
// import { formatAddress, stripEthosWallet } from '@utils/format-address';
import { useAddressDomain } from '@hooks/use-address-domain';

interface OwnProps {}

const WalletList: FC<OwnProps> = () => {
  const currentAccount = useCurrentAccount();
  const accounts = useAccounts();
  const { mutate: disconnect } = useDisconnectWallet();
  // const [, copy] = useCopyToClipboard();
  // const account = currentAccount?.address || '';
  // const domains = useModel(walletConnectModel, (state) => state.addressDomains);
  // const wallet = useCurrentWallet();
  // const { mutate: switchAccount } = useSwitchAccount();

  useAddressDomain(...accounts.map((item) => item.address));

  if (!currentAccount) return null;

  // const domain = domains[currentAccount.address]?.domain;
  // const label = stripEthosWallet(currentAccount.label);

  return (
    <>
      <div className={styles.bg_wrapper}>
        {/* <div className={styles.account_row}>
          <img src={wallet.currentWallet?.icon} className={styles.wallet_icon} />
          <div className={styles.address}>
            <p>{formatAddress({ domain, label, address: currentAccount.address })}</p>
            {!!(domain || label) && <p>{formatAddress({ address: currentAccount.address })}</p>}
          </div>
          <div
            className={styles.copy}
            onClick={() => {
              copy(account);
              toast('Address is copied');
            }}
          >
            <img src={copyIcon} />
          </div>
        </div> */}
        <ul>
          {/* <li>
            <a
              className={styles.link}
              href={globalSettingModel.explorerUrlForAccount(account)}
              target="_blank"
            >
              <img src={explorerIcon} />
              <span>View in Explorer</span>
            </a>
          </li> */}
          <li>
            <Link to="/portfolio" className={styles.link}>
              <img src={portfolioIcon} />
              <span>Portfolio</span>
            </Link>
          </li>
          {/* <li
            onClick={() => {
              walletConnectModel.toggleDialog();
            }}
          >
            <img src={changeWalletIcon} />
            <span>Change Wallet</span>
          </li> */}
          <li onClick={() => disconnect()}>
            <img src={disconnectIcon} />
            <span className={styles.red}>Disconnect</span>
          </li>
        </ul>
      </div>
      {/* {accounts.length > 1 && (
        <div className={styles.switch_account_wrapper}>
          <div className={styles.title}>Switch Account</div>
          <div className={styles.content}>
            {accounts.map((account) => {
              if (account.address === currentAccount.address) return null;
              const domain = domains[account.address]?.domain;
              const label = stripEthosWallet(account.label);

              return (
                <div
                  key={account.address}
                  onClick={() => {
                    switchAccount(
                      { account },
                      {
                        onSuccess() {
                          toast.success('Account changed successfully');
                        },
                        onError(error) {
                          toast.error(error.message);
                        },
                      },
                    );
                  }}
                >
                  <div className={styles.account_row}>
                    <img
                      src={account.icon || wallet.currentWallet?.icon}
                      className={styles.wallet_icon}
                    />
                    <div className={styles.address}>
                      <p>{formatAddress({ domain, label, address: account.address })}</p>
                      {!!(domain || label) && <p>{formatAddress({ address: account.address })}</p>}
                    </div>
                    <img src={switchIcon} className={styles.switch_icon} data-icon />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )} */}
    </>
  );
};

export default WalletList;
