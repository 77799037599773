import { type FC, memo, useState } from 'react';
import upIcon from '@assets/images/icons/limit-up.svg';
import downIcon from '@assets/images/icons/limit-down.svg';
import styles from './time-select.module.scss';
import { type DateRange } from '@constants/date-range';

interface OwnProps {
  dateRanges: DateRange[];
  changeDateRange(value: DateRange): void;
  changeVisible(value: boolean): void;
}

const TimeSelect: FC<OwnProps> = (props) => {
  const { dateRanges, changeDateRange, changeVisible } = props;
  const [dayVisible, setDayVisible] = useState(true);
  const [hourVisible, setHourVisible] = useState(true);
  const [minuteVisible, setMinuteVisible] = useState(true);

  const minutes = dateRanges.filter((item) => item.category === 'Minutes').reverse();
  const hours = dateRanges.filter((item) => item.category === 'Hours').reverse();
  const days = dateRanges.filter((item) => item.category === 'Days').reverse();

  const handleDateRange = (value: DateRange) => {
    changeDateRange(value);
    changeVisible(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        <div className={styles.grid_top} onClick={() => setMinuteVisible(!minuteVisible)}>
          Minutes
          <img src={minuteVisible ? upIcon : downIcon} />
        </div>
        <ul className={styles.grid_list} style={{ display: minuteVisible ? '' : 'none' }}>
          {minutes.map((item) => (
            <li key={item.label} onClick={() => handleDateRange(item)}>
              {/\d+/.test(item.step) ? item.step.replace(/(\d+)/, '$1 ') : `1 ${item.step}`}
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.grid}>
        <div className={styles.grid_top} onClick={() => setHourVisible(!hourVisible)}>
          Hours
          <img src={hourVisible ? upIcon : downIcon} />
        </div>
        <ul className={styles.grid_list} style={{ display: hourVisible ? '' : 'none' }}>
          {hours.map((item) => (
            <li key={item.label} onClick={() => handleDateRange(item)}>
              {/\d+/.test(item.step) ? item.step.replace(/(\d+)/, '$1 ') : `1 ${item.step}`}
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.grid}>
        <div className={styles.grid_top} onClick={() => setDayVisible(!dayVisible)}>
          Days
          <img src={dayVisible ? upIcon : downIcon} />
        </div>
        <ul className={styles.grid_list} style={{ display: dayVisible ? '' : 'none' }}>
          {days.map((item) => (
            <li key={item.label} onClick={() => handleDateRange(item)}>
              {/\d+/.test(item.step) ? item.step.replace(/(\d+)/, '$1 ') : `1 ${item.step}`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default memo(TimeSelect);
