import { type FC, memo } from 'react';
import styles from './index.module.scss';
import loadingIcon from '@assets/images/pictures/loading.png';
import loadingThinIcon from '@assets/images/pictures/loading-thin.png';

interface OwnProps {
  size?: number;
  /**
   * Defaults `false`
   */
  centered?: boolean;
  /**
   * Defaults `false`
   */
  thinIcon?: boolean;
  className?: string;
}

const Loading: FC<OwnProps> = ({
  size = 24,
  centered = false,
  thinIcon = false,
  className = '',
}) => {
  return (
    <img
      width={size}
      height={size}
      className={`${styles.loading} ${centered ? styles.center : ''} ${className}`}
      src={thinIcon ? loadingThinIcon : loadingIcon}
    />
  );
};

export default memo(Loading);
