import { useEffect, useMemo, useState } from 'react';
import { turbosSdk } from 'src/services/turbos-sdk';
import { useAddress } from './use-address';
import { Trade } from 'turbos-clmm-sdk';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { useRefreshFlag } from './use-refresh';
import { isEqual } from 'lodash-es';

export interface TurbosBestRouteResult {
  pool: string;
  swapResult: Trade.ComputedSwapResult;
  amountIn: string;
  amountOut: string;
  nextTickIndex: number;
}

interface PreData {
  amount: string;
  amountSpecifiedIsInput: boolean;
  address: string;
  atob: boolean;
}

export const usePumpSwapRoute = (
  pool_id: string,
  amount: string | undefined,
  amountSpecifiedIsInput: boolean,
  atob: boolean,
) => {
  const { refresh } = useRefreshFlag();
  const [best, setBest] = useState<TurbosBestRouteResult[]>();
  const [loading, setLoading] = useState(false);
  const [_, setPrevData] = useState<PreData | undefined>();
  const address = useAddress();

  let _address = address || '0xc851a734b97870c41435b06c8254f1ef4cef0d53cfe1bcb0ba21a175b528311e';
  useEffect(() => {
    if (!amount || amount === '0' || !_address) {
      setBest(undefined);
      setLoading(false);
      setPrevData(undefined);
      return;
    }

    let opExpired: boolean = false;
    const selectBestPool = async (
      pools: { pool: string; a2b: boolean; amountSpecified: number | string }[],
    ): Promise<Trade.ComputedSwapResult[]> => {
      const swapResults = await new Promise<Trade.ComputedSwapResult[]>((resolve, reject) => {
        let handled = false;
        // Some kind of fullnode are interrupted forever.
        setTimeout(() => {
          handled = true;
          Sentry.captureException('best route request timed out 30s');
          resolve([]);
        }, 30_000);

        turbosSdk.trade
          .computeSwapResultV2({
            pools,
            amountSpecifiedIsInput,
            address: _address,
            tickStep: 50000,
          })
          .then((data) => {
            handled || resolve(data);
          })
          .catch((e) => {
            if (handled) return;
            // Error: Object runtime cached objects limit (1000 entries) reached
            if ((e as Error).message.includes('MEMORY_LIMIT_EXCEEDED')) {
              resolve([]);
            } else {
              reject(e);
            }
          });
      });
      return swapResults;
    };

    const getBestRoute = async () => {
      if (opExpired) return;
      let bestPools: TurbosBestRouteResult[] = [];
      const oneSwapResults = await selectBestPool([
        {
          pool: pool_id,
          a2b: atob,
          amountSpecified: amount,
        },
      ]);
      bestPools = oneSwapResults.map((swapResult) => {
        return {
          pool: pool_id,
          swapResult,
          amountIn: swapResult.a_to_b ? swapResult.amount_a : swapResult.amount_b,
          amountOut: swapResult.a_to_b ? swapResult.amount_b : swapResult.amount_a,
          nextTickIndex: turbosSdk.math.bitsToNumber(swapResult.tick_current_index.bits),
        };
      });
      // .filter(
      //   (item) =>
      //     (amountSpecifiedIsInput && item.amountIn === amount) ||
      //     (!amountSpecifiedIsInput && item.amountOut === amount),
      // );
      if (opExpired) return;
      setBest(bestPools);
    };

    const timer = setTimeout(async () => {
      const current = {
        atob,
        amount,
        amountSpecifiedIsInput,
        address: _address,
      };
      setPrevData((pre) => {
        if (!isEqual(current, pre)) {
          setLoading(true);
          setBest(undefined);
        }
        return pre;
      });

      try {
        await getBestRoute();
        setPrevData(current);
      } catch (e) {
        toast((e as Error).message, { type: 'error' });
        Sentry.captureException(e);
      } finally {
        opExpired || setLoading(false);
      }
    }, 280);

    return () => {
      clearTimeout(timer);
      opExpired = true;
    };
  }, [amount, amountSpecifiedIsInput, _address, atob, refresh]);

  const amountIn = amountSpecifiedIsInput ? amount : best?.[0]?.amountIn;
  const amountOut = amountSpecifiedIsInput ? best?.[best.length - 1]?.amountOut : amount;

  const insufficientLiquidity = useMemo(() => {
    if (!best) {
      return false;
    }

    if (amountIn === '0' || amountOut === '0' || !best.length) {
      return true;
    }

    return best.some((item) => {
      return (item.swapResult.is_exact_in && item.swapResult.a_to_b) ||
        (!item.swapResult.is_exact_in && !item.swapResult.a_to_b)
        ? item.swapResult.amount_a !== amount
        : item.swapResult.amount_b !== amount;
    });
  }, [amountIn, amountOut, best]);
  const insufficientBalance = amountSpecifiedIsInput ? amountIn === amount : amountOut === amount;
  return {
    fetchingBestRoute: loading,
    bestRoute: insufficientLiquidity || !insufficientBalance ? undefined : best,
    amountIn: amountIn === '0' || !insufficientBalance ? undefined : amountIn,
    amountOut: amountOut === '0' || !insufficientBalance ? undefined : amountOut,
    insufficientLiquidity: insufficientLiquidity,
  };
};
