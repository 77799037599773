import PumpEmpty from '@components/pump-empty';
import DataList from '@components/pump-list/list';
import { useAddress } from '@hooks/use-address';
import { usePumpPools } from '@hooks/use-pump-pool';
import Loading from '@ui/loading';
import { type FC } from 'react';
import styles from './index.module.scss';

const PumpCreate: FC = () => {
  const address = useAddress();
  const { pools, loading } = usePumpPools({
    page: 1,
    creator: address,
    pageSize: 100,
    reload: 1,
  });

  return (
    <div>
      {loading ? (
        <div className={styles.loading}>
          <Loading size={32}></Loading>
        </div>
      ) : (
        <>
          {pools.data.length === 0 ? (
            <PumpEmpty />
          ) : (
            <>
              <div className={styles.items}>
                {pools.data.map((pool) => (
                  <DataList pool={pool} key={pool.pool_id} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PumpCreate;
