import Pagination from '@components/pagination';
import { usePumpHolder } from '@hooks/use-pump-holder';
import Loading from '@ui/loading';
import { OpenapiClientPump } from 'foca-openapi';
import { useState, type FC } from 'react';
import styles from './index.module.scss';
import List from './list';

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const Holder: FC<OwnerProps> = ({ pool }) => {
  const [page, setPage] = useState(1);
  const holders = usePumpHolder(pool.token_address);
  const total = holders?.length || 0;
  const pageSize = 10;
  const finalHolders = holders?.slice((page - 1) * pageSize, page * pageSize);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Top Token Holders</div>
      <ul className={styles.items}>
        <li>
          <div className={styles.th}>Holder</div>
          <div className={styles.th}>Percentage</div>
        </li>
        {finalHolders?.map((item) => (
          <List item={item} pool={pool} key={item.wallet_address} />
        ))}
      </ul>
      {!holders && (
        <div className={styles.loading}>
          <Loading size={36} />
        </div>
      )}
      {total > pageSize && (
        <Pagination page={page} limit={pageSize} onChange={setPage} total={total} />
      )}
    </div>
  );
};

export default Holder;
