import { type FC, memo, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.scss';

const Toast: FC = () => {
  useEffect(() => {
    return toast.onChange(({ status, id }) => {
      if (status === 'added' && !document.hasFocus()) {
        toast.dismiss(id);
      }
    });
  }, []);

  return (
    <div className={styles.toastContent}>
      <ToastContainer
        pauseOnFocusLoss={false}
        hideProgressBar
        theme="dark"
        className={styles.wrapper}
        toastClassName={styles.toast}
      />
    </div>
  );
};

export default memo(Toast);
