const DAY = 24 * 3600_000;
const MONTH = 30 * DAY;
const YEAR = 12 * MONTH;

export interface DateRange {
  label: string;
  value: number;
  step: 'week' | 'day' | '4hour' | 'hour' | 'minute' | '5minute' | '15minute' | '30minute';
  stepLabel: string;
  timeVisible: boolean;
  category: 'Minutes' | 'Hours' | 'Days' | '';
}

export const dateRanges: DateRange[] = [
  {
    label: '1m',
    value: DAY,
    step: 'minute',
    stepLabel: '1m',
    timeVisible: true,
    category: '',
  },
  {
    label: '5m',
    value: 5 * DAY,
    step: '5minute',
    stepLabel: '5m',
    timeVisible: true,
    category: '',
  },
  {
    label: '15m',
    value: 15 * DAY,
    step: '15minute',
    stepLabel: '15m',
    timeVisible: true,
    category: '',
  },
  {
    label: '30m',
    value: MONTH,
    step: '30minute',
    stepLabel: '30m',
    timeVisible: true,
    category: '',
  },
  {
    label: '1h',
    value: 45 * DAY,
    step: 'hour',
    stepLabel: '1h',
    timeVisible: true,
    category: '',
  },

  {
    label: '1 minute',
    value: DAY,
    step: 'minute',
    stepLabel: '1m',
    timeVisible: true,
    category: 'Minutes',
  },
  {
    label: '5 minutes',
    value: 5 * DAY,
    step: '5minute',
    stepLabel: '5m',
    timeVisible: true,
    category: 'Minutes',
  },
  {
    label: '15 minutes',
    value: 15 * DAY,
    step: '15minute',
    stepLabel: '15m',
    timeVisible: true,
    category: 'Minutes',
  },
  {
    label: '30 minutes',
    value: MONTH,
    step: '30minute',
    stepLabel: '30m',
    timeVisible: true,
    category: 'Minutes',
  },
  {
    label: '1 hour',
    value: 45 * DAY,
    step: 'hour',
    stepLabel: '1h',
    timeVisible: false,
    category: 'Hours',
  },
  {
    label: '4 hours',
    value: (50 + 16) * DAY,
    step: '4hour',
    stepLabel: '4h',
    timeVisible: false,
    category: 'Hours',
  },
  {
    label: '1 day',
    value: (10 + 10) * MONTH,
    step: 'day',
    stepLabel: '1D',
    timeVisible: false,
    category: 'Days',
  },
  {
    label: '1 week',
    value: (5 + 5) * YEAR,
    step: 'week',
    stepLabel: '1W',
    timeVisible: false,
    category: 'Days',
  },
];
