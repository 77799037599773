import { type FetchPoolsParams, pumpPoolModel } from '@models/pump-pool.model';
import { useModel } from 'foca';
import { OpenapiClientPump } from 'foca-openapi';
import { isEqual, throttle } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useRefreshFlag } from './use-refresh';

const fetchPools = throttle(pumpPoolModel.fetchPools, 10, { leading: true, trailing: false });

export const usePumpPools = (options: FetchPoolsParams & { reload: number }) => {
  const { reload, ...params } = options;
  const { refresh } = useRefreshFlag();
  const [pools, setPools] = useState<OpenapiClientPump.GetPoolsResponse>({
    page: 1,
    pageSize: 10,
    total: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);

  const [_, setPre] = useState<FetchPoolsParams & { reload: number }>();

  const fetchData = async () => {
    setPre((pre) => {
      if (!isEqual(options, pre)) {
        setLoading(true);
        return options;
      }
      return pre;
    });
    const result = await fetchPools(params);
    setPools(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(options), refresh]);

  return { loading, pools };
};

const fetchPool = throttle(pumpPoolModel.fetchPoolByTokenAddress, 10, {
  leading: true,
  trailing: false,
});
export const usePumpPool = (tokenAddress: string) => {
  const { refresh } = useRefreshFlag();
  const pool = useModel(pumpPoolModel, (state) => state.poolMap[tokenAddress]);
  useEffect(() => {
    fetchPool(tokenAddress);
  }, [tokenAddress, refresh]);
  return pool;
};
