import Pagination from '@components/pagination';
import { usePumpTradeHistory } from '@hooks/use-pump-history';
import Loading from '@ui/loading';
import { OpenapiClientPump } from 'foca-openapi';
import { useState, type FC } from 'react';
import styles from './index.module.scss';
import List from './list';
import Tr from './tr';

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const PumpHistory: FC<OwnerProps> = ({ pool }) => {
  const [page, setPage] = useState(1);

  const { tradeHistories, loading } = usePumpTradeHistory(pool.token_address, page);

  if (!tradeHistories || loading)
    return (
      <div className={styles.loading}>
        <Loading size={36} />
      </div>
    );

  return (
    <>
      <table width="100%" className={styles.table}>
        <thead>
          <tr>
            <th align="left">Account</th>
            <th align="center">Type</th>
            <th align="center">SUI</th>
            <th align="center">${pool.symbol}</th>
            <th align="center">Date</th>
            <th align="right">Transaction</th>
          </tr>
        </thead>

        <tbody>
          {tradeHistories.data.map((item) => (
            <Tr key={item.tx_digest} item={item} />
          ))}
        </tbody>
      </table>

      <ul className={styles.item}>
        {tradeHistories.data.map((item) => (
          <List key={item.tx_digest} item={item} pool={pool} />
        ))}
      </ul>

      {tradeHistories.total > tradeHistories.pageSize && (
        <Pagination onChange={setPage} page={page} total={tradeHistories.total} />
      )}
    </>
  );
};

export default PumpHistory;
