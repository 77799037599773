import { type FC } from 'react';
import styles from './index.module.scss';
import king from '@assets/images/pump/king.svg';
import PumpList from '@components/pump-list';
import copyIcon from '@assets/images/pump/copy.svg';
import Top from '@components/top';
import { useNavigate } from 'react-router-dom';
import { formatAddress } from '@mysten/sui/utils';
import { usePumpKing } from '@hooks/use-pump-king';
import Loading from '@ui/loading';
import { Decimal } from 'turbos-clmm-sdk';
import { pumpSdk } from '@libs/pump';
import Footer from '@components/footer';
import Banner from '@components/banner';
import { formatContract } from '@utils/format-contract';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';
import { useResolveSuiNSName } from '@mysten/dapp-kit';

const Pump: FC = () => {
  const navigate = useNavigate();
  const [, copy] = useCopyToClipboard();
  const { loading, data } = usePumpKing();
  let ratio = new Decimal(data?.real_sui_reserves || '0')
    .div(pumpSdk.fair_launch_sui_amount)
    .mul(100)
    .toFixed(2);

  ratio = Number(ratio) > 100 ? '100' : ratio;

  const suiNames = useResolveSuiNSName(data?.created_by);
  return (
    <>
      <Top />
      <Banner />
      <div className={styles.on_sui}>
        <div className={styles.p1}>Fun on Sui</div>
        <div className={styles.p2}>Fun on Sui</div>
        <div className={styles.p3}>Fun on Sui</div>
      </div>
      <div className={styles.launch}>Each token on Turbos.Fun is a fair launch</div>
      <div className={styles.king}>
        <img src={king} />
        <span>King of Racing</span>
      </div>

      {loading ? (
        <div className={styles.loading}>
          <Loading size={36} />
        </div>
      ) : !data ? null : (
        <div className={styles.first} onClick={() => navigate(`/fun/${data.token_address}`)}>
          <div className={styles.icon}>
            <img src={data.token_metadata.iconUrl} />
          </div>

          <div className={styles.content}>
            <div className={styles.title}>
              {data.name} (${data.symbol})
            </div>
            <div className={styles.contract}>
              CA: <span>{formatContract(data.token_address)}</span>
              <img
                src={copyIcon}
                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  event.stopPropagation();
                  copy(data.token_address);
                  toast('Address is copied');
                }}
              />
            </div>

            <div className={styles.description}>{data.description}</div>
            <div className={styles.line}>
              <p>Created by:</p>
              <p className={styles.address}>
                {suiNames.data ? suiNames.data : formatAddress(data.created_by)}
              </p>
            </div>
            <div className={styles.line}>
              <p>Bonding Curve Progress:</p>
              <p className={styles.liquidity}>{ratio}%</p>
            </div>
            <div className={styles.percent}>
              <div className={styles.width} style={{ width: `${ratio}%` }}></div>
            </div>
          </div>
        </div>
      )}

      <PumpList />
      <Footer />
    </>
  );
};

export default Pump;
