import { type FC, memo } from 'react';
import backIcon from '@assets/images/icons/back.svg';
import styles from './index.module.scss';

interface OwnProps {
  onClick?(): void;
  message?: string;
  className?: string;
}

const Back: FC<OwnProps> = ({ message, onClick, className = '' }) => {
  return (
    <div className={`${styles.back} ${className}`} onClick={onClick}>
      <img src={backIcon} />
      <span>{message}</span>
    </div>
  );
};

export default memo(Back);
