import { Network } from 'turbos-clmm-sdk';

const production = {
  url: 'https://app.turbos.finance',
  sui_network: Network.mainnet,
  api_url: 'https://api.turbos.finance/fun',
  // api_url: 'http://54.234.151.46:6002',
  explorer_url: {
    suivision: {
      txb: 'https://suivision.xyz/txblock/{{txb}}',
      account: 'https://suivision.xyz/account/{{account}}',
      object: 'https://suivision.xyz/object/{{object}}',
      coin: 'https://suivision.xyz/coin/{{coin}}',
    },
    suiscan: {
      txb: 'https://suiscan.xyz/mainnet/tx/{{txb}}',
      account: 'https://suiscan.xyz/mainnet/account/{{account}}',
      object: 'https://suiscan.xyz/mainnet/object/{{object}}',
      coin: 'https://suiscan.xyz/mainnet/coin/{{coin}}',
    },
    sui_explorer: {
      txb: 'https://suiexplorer.com/txblock/{{txb}}',
      account: 'https://suiexplorer.com/address/{{account}}',
      object: 'https://suiexplorer.com/object/{{object}}',
      coin: 'https://suiexplorer.com/object/{{coin}}',
    },
  },
  rpc: {
    suiscan: 'https://rpc-mainnet.suiscan.xyz/',
    sui_Official: 'https://fullnode.mainnet.sui.io:443',
    suiet: ' https://mainnet.suiet.app/',
    shinami: 'https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa',
  },
  // fullnode: 'https://sui-mainnet-endpoint.blockvision.org',
  // suinami node: https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa
  // fullnode: 'https://turbos-mainnet-endpoint.blockvision.org',
  fullnode: 'https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa',
  // fullnode: 'https://fullnode.mainnet.sui.io:443',
  pyth_endpoint: 'https://hermes.pyth.network',
  fun: {
    originPackageId: '0x96e1396c8a771c8ae404b86328dc27e7b66af39847a31926980c96dbc1096a15',
    packageId: '0x96e1396c8a771c8ae404b86328dc27e7b66af39847a31926980c96dbc1096a15',
    globalConfig: '0xd86685fc3c3d989385b9311ef55bfc01653105670209ac4276ebb6c83d7df928',
    bytecodeBase64:
      'oRzrCwYAAAAKAQAMAgweAyonBFEIBVlXB7ABzAEI/AJgBtwDKwqHBAUMjAQsABABCwIGAhECEgITAAICAAEBBwEAAAIADAEAAQIDDAEAAQQEAgAFBQcAAAkAAQABDwUGAQACBwgJAQIDDAUBAQwDDQ4BAQwEDgsMAAUKAwQAAQQCBwMKBA0CCAAHCAQAAgsCAQgACwMBCAABCgIBCAUBCQABCwEBCQABCAAHCQACCgIKAgoCCwEBCAUHCAQCCwMBCQALAgEJAAELAgEIAAEGCAQBBQELAwEIAAIJAAUMQ29pbk1ldGFkYXRhBk9wdGlvbghURU1QTEFURQtUcmVhc3VyeUNhcAlUeENvbnRleHQDVXJsBGNvaW4PY3JlYXRlX2N1cnJlbmN5C2R1bW15X2ZpZWxkBGluaXQVbmV3X3Vuc2FmZV9mcm9tX2J5dGVzBm9wdGlvbhRwdWJsaWNfZnJlZXplX29iamVjdA9wdWJsaWNfdHJhbnNmZXIGc2VuZGVyBHNvbWUIdGVtcGxhdGUIdHJhbnNmZXIKdHhfY29udGV4dAN1cmwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAICAQYKAgcGU1lNQk9MCgIFBE5BTUUKAgwLREVTQ1JJUFRJT04KAgQDVVJMAAIBCAEAAAAAAhQLAAcABwEHAgcDBwQRBjgACgE4AQwCDAMLAjgCCwMLAS4RBTgDAgA=',
  },
};

export type Config = typeof production;

export default production;
