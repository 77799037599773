import { useState, type FC } from 'react';
import styles from './index.module.scss';
import disconnectIcon from '@assets/images/icons/disconnect.svg';
import copyIcon from '@assets/images/pump/copy.svg';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'react-toastify';
import { useCurrentWallet, useDisconnectWallet } from '@mysten/dapp-kit';
import { useAddress } from '@hooks/use-address';
import { formatAddress } from '@mysten/sui/utils';
import PumpOwned from '@components/pump-owned';
import PumpCreate from '@components/pump-create';
import { walletConnectModel } from '@models/wallet-connect.model';
import Footer from '@components/footer';

const tabs = [
  {
    label: 'Token Owned',
    value: 'owned',
  },
  {
    label: 'Token Created',
    value: 'created',
  },
];

const Portfolio: FC = () => {
  const address = useAddress();
  const wallet = useCurrentWallet();
  const [, copy] = useCopyToClipboard();
  const { mutate: disconnect } = useDisconnectWallet();
  const [tab, setTab] = useState('owned');

  return (
    <>
      <div className={styles.wrapper}>
        {!address ? (
          <div className={styles.connect}>
            <div className={styles.btn} onClick={walletConnectModel.toggleDialog}>
              Connect Wallet
            </div>
          </div>
        ) : (
          <div className={styles.wallet}>
            <img src={wallet.currentWallet?.icon} className={styles.icon} />
            <div className={styles.wallet_info}>
              <div className={styles.name}>{wallet.currentWallet?.name}</div>
              <div className={styles.address}>
                {formatAddress(address || '')}
                <img
                  src={copyIcon}
                  onClick={() => {
                    if (!address) return;
                    copy(address);
                    toast('Address is copied');
                  }}
                />
              </div>
            </div>
            <div className={styles.disconnect} onClick={() => disconnect()}>
              <img src={disconnectIcon} />
              <span className={styles.red}>Disconnect</span>
            </div>
          </div>
        )}
        <div className={styles.tabs}>
          <ul className={styles.items}>
            {tabs.map((item) => (
              <li
                key={item.value}
                onClick={() => setTab(item.value)}
                className={item.value === tab ? styles.active : ''}
              >
                {item.label}
              </li>
            ))}
          </ul>

          {tab === 'owned' && <PumpOwned />}
          {tab === 'created' && <PumpCreate />}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Portfolio;
