import { auth } from '@libs/auth';
import http from '@libs/http';
import { useSignPersonalMessage } from '@mysten/dapp-kit';
import { type LaunchData } from '@pages/launch';
import { useEffect, useState } from 'react';
import { useAddress } from './use-address';

export interface PreheatData extends LaunchData {
  is_published: boolean;
  id: number;
  uri: string;
}

export const useGetFun = () => {
  const address = useAddress();
  const { mutateAsync: signMessage } = useSignPersonalMessage();
  const [data, setData] = useState<PreheatData>();

  const getPreheat = async () => {
    if (!address) return;
    try {
      const token = await auth(address, signMessage, true);

      const result = await http.get<PreheatData>('/preheat', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(result);
    } catch (err) {
      setData(undefined);
    }
  };

  useEffect(() => {
    getPreheat();
  }, [address, signMessage]);

  return data;
};
