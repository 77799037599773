import http from '@libs/http';

export const auth = async (
  address: string,
  signMessage: (options: { message: Uint8Array }) => Promise<{ signature: string }>,
  noCache: boolean = true,
) => {
  const token = localStorage.getItem(`${address}-authorization`);
  if (token) return token;

  const result = await http.post<{ nonce: string }>('/passport/nonce', {
    walletAddress: address,
  });
  const signResult = await signMessage({
    message: new TextEncoder().encode(result.nonce),
  });
  const res = await http.post<{
    expires_at: number;
    token: string;
    walletAddress: string;
  }>('/passport/auth', {
    walletAddress: address,
    signature: signResult.signature,
  });
  noCache && localStorage.setItem(`${address}-authorization`, res.token);
  return res.token;
};
