import { type FC } from 'react';
import styles from './index.module.scss';
import Marquee from 'react-fast-marquee';
import { usePumpTradePresent } from '@hooks/use-pump-trade';
import { formatAddress } from '@mysten/sui/utils';
import { OpenapiClientPump } from 'foca-openapi';
import { Link } from 'react-router-dom';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { useResolveSuiNSName } from '@mysten/dapp-kit';

export const Top: FC = () => {
  const present = usePumpTradePresent();
  if (present.length < 1) return null;
  return (
    <div className={styles.top}>
      <Marquee
        direction="left" // 设置滚动方向为左
        speed={75} // 设置滚动速度
        gradient={false} // 关闭渐变效果（根据需要）
        pauseOnHover={true}
        className={styles.marquee}
      >
        {present.map((item) => (
          <List data={item} key={item.tx_digest} />
        ))}
      </Marquee>
    </div>
  );
};

const List: FC<{ data: OpenapiClientPump.GetTradePresentResponse[number] }> = ({ data }) => {
  const suiNames = useResolveSuiNSName(data.sender);
  return (
    <Link to={`/fun/${data.token_address}`}>
      <div className={styles.items} key={data.tx_digest}>
        <span>{suiNames.data ? suiNames.data : formatAddress(data.sender)}</span>
        {data.is_buy ? 'Bought' : 'Sold'}
        <span>
          {bigNumberToReadable(data.token_amount, 0, true)} ${data.token_metadata?.symbol || ''}
        </span>
        <img src={data.token_metadata?.iconUrl || ''} />
      </div>
    </Link>
  );
};

export default Top;
