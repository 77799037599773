export const parseGlobIcon = (icons: Record<string, string>) => {
  const data = Object.fromEntries(
    Object.entries(icons).map(([key, value]) => {
      return [key.split('/').pop(), value];
    }),
  ) as Record<string, string>;
  return (icon: string): string => {
    return (
      data[icon + '.svg'] ||
      data[icon + '.png'] ||
      data[icon + '.jpg'] ||
      data[icon + '.jpeg'] ||
      data[icon + '.gif'] ||
      ''
    );
  };
};
