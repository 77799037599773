import React, { memo, type FC } from 'react';
import ModernDrawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

type DrawerProps<T> = T extends React.FC<infer R> ? R : never;

interface OwnProps extends DrawerProps<typeof ModernDrawer> {}

const Drawer: FC<OwnProps> = (props) => {
  return <ModernDrawer duration={200} lockBackgroundScroll {...props}></ModernDrawer>;
};

export default memo(Drawer);
