import { globalSettingModel } from '@models/global-setting.model';
import { useResolveSuiNSName } from '@mysten/dapp-kit';
import { formatAddress } from '@mysten/sui/utils';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './list.module.scss';

interface OwnerProps {
  item: OpenapiClientPump.GetTokenCoinsHoldersResponse[number];
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
}

const List: FC<OwnerProps> = ({ item, pool }) => {
  const suiNames = useResolveSuiNSName(item.wallet_address);
  const isCreated = pool.created_by === item.wallet_address;
  return (
    <li key={item.wallet_address}>
      <div className={styles.holder}>
        <Link to={globalSettingModel.explorerUrlForAccount(item.wallet_address)} target="_blank">
          {suiNames.data ? suiNames.data : formatAddress(item.wallet_address)}{' '}
          {isCreated ? '(Creator)' : ''}
        </Link>
      </div>
      <div>
        {bigNumberToReadable(new Decimal(item.percentage).mul(100).toString(), 0, false, 2)}%
      </div>
    </li>
  );
};

export default List;
