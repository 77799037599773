import { pumpApi } from '@libs/openapi';
import { OpenapiClientPump } from 'foca-openapi';
import { useEffect, useState } from 'react';

export const usePumpRankingLaunching = () => {
  const [loading, setLoading] = useState(false);
  const [launching, setLaunching] = useState<OpenapiClientPump.GetRankingsBoundingCurveResponse>();

  const getRankingsBoundingCurve = async () => {
    setLoading(true);
    const result = await pumpApi.ranking.getRankingsBoundingCurve();
    setLaunching(result);
    setLoading(false);
  };
  useEffect(() => {
    getRankingsBoundingCurve();
  }, []);

  return {
    launching,
    loading,
  };
};

export const usePumpRankingMarketCap = () => {
  const [loading, setLoading] = useState(false);
  const [marketCap, setMarketCap] = useState<OpenapiClientPump.GetRankingsMarketCapResponse>();

  const getRankingsMarketCap = async () => {
    setLoading(true);
    const result = await pumpApi.ranking.getRankingsMarketCap();
    setMarketCap(result);
    setLoading(false);
  };
  useEffect(() => {
    getRankingsMarketCap();
  }, []);

  return {
    marketCap,
    loading,
  };
};
