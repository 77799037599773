import { isSuiCoinAddress } from './is-sui-coin-address';

export const formatSuiNetworkAddress = (type: string) => {
  if (!type || isSuiCoinAddress(type)) {
    return type;
  }
  const types = type.split('::');
  if (!types[0]) return type;

  const id = types[0]!.replace('0x', '');
  const len = 64 - id.length;
  types[0] = '0x' + new Array(len).fill('0').join('') + id;
  return types.join('::');
};
