import { pumpTradeModel } from '@models/pump-trade.model';
import { useModel } from 'foca';
import { throttle } from 'lodash-es';
import { useEffect } from 'react';

const fetchLatestPrice = throttle(pumpTradeModel.fetchLatestPrice, 10, {
  leading: true,
  trailing: false,
});

export const useLatestPrice = (tokenAddress: string) => {
  const latestPrice = useModel(pumpTradeModel, (state) => state.latestPrice[tokenAddress]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    const loop = () => {
      tokenAddress && fetchLatestPrice(tokenAddress);
      timer = setTimeout(() => {
        loop();
      }, 3000);
    };
    loop();
    return () => {
      clearTimeout(timer);
    };
  }, [tokenAddress]);

  return latestPrice;
};
