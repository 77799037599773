import { pumpApi } from '@libs/openapi';
import { defineModel } from 'foca';
import type { OpenapiClientPump } from 'foca-openapi';

export type SortParams = OpenapiClientPump.GetPoolsQuery['sort'];

export interface FetchPoolsParams {
  search?: string;
  sort?: SortParams;
  completed?: boolean;
  page: number;
  pageSize?: number;
  creator?: string;
  direction?: 'desc' | 'asc';
}

const initialState: {
  poolList: OpenapiClientPump.GetPoolsResponse;
  poolMap: Record<string, OpenapiClientPump.GetPoolsByTokenAddressResponse>;
} = {
  poolList: { page: 1, pageSize: 10, total: 0, data: [] },
  poolMap: {},
};

export const pumpPoolModel = defineModel('pump-pool', {
  initialState,
  methods: {
    async fetchPools(params: FetchPoolsParams) {
      const { search, sort, completed, page, creator, pageSize = 10, direction = 'desc' } = params;
      const result = await pumpApi.pool.getPools({
        query: { search, sort, completed, page, pageSize, creator, direction },
      });
      this.setState({ poolList: result });
      return result;
    },
    async fetchPoolByTokenAddress(tokenAddress: string) {
      const result = await pumpApi.pool.getPoolsByTokenAddress({ params: { tokenAddress } });
      this.setState((state) => {
        state.poolMap[tokenAddress] = result;
      });
    },
  },
});
