import Footer from '@components/footer';
import SubmitLaunch from '@components/submit-launch';
import Top from '@components/top';
import UploadFile from '@components/upload-file';
import { useAddress } from '@hooks/use-address';
import { useGetFun } from '@hooks/use-fun';
import { pumpSdk } from '@libs/pump';
import Input from '@ui/input';
import Textarea from '@ui/input/textarea';
import { ReactElement, useEffect, useState, type FC } from 'react';
import { useDebounce } from 'react-use';
import styles from './index.module.scss';

const tabs: { value: 0 | 1; label: string; des: ReactElement }[] = [
  {
    value: 0,
    label: 'Lock your LP',
    des: (
      <>
        You’re able to <span>earn all the trading fees and mining rewards</span> even though your LP
        position is fully locked.
      </>
    ),
  },
  {
    value: 1,
    label: 'Burn your LP',
    des: (
      <>
        Your liquidity position will be permanently burned. You will be unable to access or withdraw
        underlying position assets, and you will not be able to claim transaction fees and earned
        mining rewards.
      </>
    ),
  },
];

export interface LaunchData {
  url: string;
  name: string;
  symbol: string;
  description: string;
  twitter: string;
  telegram: string;
  website: string;
  lp_type: 0 | 1;
}

const Launch: FC = () => {
  const address = useAddress();
  const [uri, setUri] = useState('');
  const [data, setData] = useState<LaunchData>({
    url: '',
    name: '',
    symbol: '',
    description: '',
    twitter: '',
    telegram: '',
    website: '',
    lp_type: 0,
  });

  const clearData = () => {
    setData({
      url: '',
      name: '',
      symbol: '',
      description: '',
      twitter: '',
      telegram: '',
      website: '',
      lp_type: 0,
    });
  };

  const funData = useGetFun();

  useEffect(() => {
    if (funData && !funData.is_published) {
      setData({
        url: funData.uri,
        name: funData.name,
        symbol: funData.symbol,
        description: funData.description,
        twitter: funData.twitter,
        telegram: funData.telegram,
        website: funData.website,
        lp_type: funData.lp_type,
      });
    }
  }, [funData]);

  useEffect(() => {
    if (!address) {
      clearData();
    }
  }, [address]);

  useDebounce(
    () => {
      setData({ ...data, url: uri });
    },
    30,
    [uri],
  );

  const tabValue = tabs.find((tab) => tab.value === data.lp_type);
  return (
    <>
      <Top />
      <div className={styles.wrapper}>
        <div className={styles.p1}>Launch your token on Turbos.Fun</div>
        <div className={styles.p2}>Simple, Fast, Low Gas Cost</div>
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.upload}>
            <UploadFile value={data.url} onChange={setUri} />
          </div>
          <div className={styles.top}>
            <Input
              maxLength={20}
              value={data.name}
              onChangeText={(value) => {
                setData({ ...data, name: value });
              }}
              wrapperClassName={styles.wrapper_input}
              prefix={
                <div className={styles.wrapper_title}>
                  Project Name *<span>{data.name.length}/20</span>
                </div>
              }
            />

            <div className={styles.block}>
              <Input
                maxLength={10}
                value={data.symbol}
                onChangeText={(value) => {
                  setData({ ...data, symbol: value });
                }}
                wrapperClassName={styles.wrapper_input}
                prefix={
                  <div className={styles.wrapper_title}>
                    Token Symbol ($Ticker) *<span>{data.symbol.length}/10</span>
                  </div>
                }
              />
              {data.symbol && !/^[A-Z][A-Za-z0-9_]*$/.test(data.symbol) ? (
                <div className={styles.error_tip}>
                  * String The first letter is capitalized, followed by English letters, numbers,
                  and _
                </div>
              ) : data.symbol && data.name && data.symbol === data.name ? (
                <div className={styles.error_tip}>
                  * Token symbol and token name must be different!
                </div>
              ) : null}
            </div>
          </div>

          <Textarea
            maxLength={250}
            rows={4}
            value={data.description}
            onChangeText={(value) => {
              setData({ ...data, description: value });
            }}
            wrapperClassName={styles.wrapper_textarea}
            prefix={
              <div className={styles.wrapper_title}>
                Project Description *<span>{data.description.length}/250</span>
              </div>
            }
          />

          <div className={styles.block}>
            <Input
              value={data.website}
              onChangeText={(value) => {
                setData({ ...data, website: value });
              }}
              wrapperClassName={styles.wrapper_input}
              prefix={<div className={styles.wrapper_title}>Website (Optional)</div>}
            />
          </div>

          <div className={styles.top}>
            <div className={styles.block}>
              <Input
                value={data.telegram}
                onChangeText={(value) => {
                  setData({ ...data, telegram: value });
                }}
                wrapperClassName={styles.wrapper_input}
                prefix={<div className={styles.wrapper_title}>Telegram (Optional)</div>}
              />
            </div>

            <div className={styles.block}>
              <Input
                value={data.twitter}
                onChangeText={(value) => {
                  setData({ ...data, twitter: value });
                }}
                wrapperClassName={styles.wrapper_input}
                prefix={<div className={styles.wrapper_title}>Twitter (Optional)</div>}
              />
            </div>
          </div>
          <div className={styles.tabs}>
            {tabs.map((tab) => (
              <div
                key={tab.value}
                onClick={() => {
                  setData({ ...data, lp_type: tab.value });
                }}
                className={tab.value === data.lp_type ? styles.active : ''}
              >
                {tab.label}
              </div>
            ))}
          </div>
          <div className={styles.des}>{tabValue?.des}</div>

          <div className={styles.div}>
            <div className={styles.p}>Tip: coin data cannot be changed after creation</div>
            <div className={styles.p}>
              Cost to deploy: <span>{pumpSdk.deployment_fee} SUl</span>
            </div>
          </div>

          <SubmitLaunch data={data} clearData={clearData} funData={funData} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Launch;
