import { Network } from 'turbos-clmm-sdk';
import production, { type Config } from './mainnet';

const development: Config = {
  ...production,
  url: 'http://clmm.turbos.s3-website-us-east-1.amazonaws.com',
  sui_network: Network.devnet,
  api_url: 'http://34.235.165.224:6012',
  // api_url: 'https://api.turbos.finance/testnet/',
  explorer_url: {
    suiscan: {
      txb: 'https://suiscan.xyz/testnet/tx/{{txb}}',
      account: 'https://suiscan.xyz/testnet/account/{{account}}',
      object: 'https://suiscan.xyz/testnet/object/{{object}}',
      coin: 'https://suiscan.xyz/testnet/coin/{{coin}}',
    },
    suivision: {
      txb: 'https://testnet.suivision.xyz/txblock/{{txb}}',
      account: 'https://testnet.suivision.xyz/account/{{account}}',
      object: 'https://testnet.suivision.xyz/object/{{object}}',
      coin: 'https://testnet.suivision.xyz/coin/{{coin}}',
    },
    sui_explorer: {
      txb: 'https://suiexplorer.com/txblock/{{txb}}?network=testnet',
      account: 'https://suiexplorer.com/address/{{account}}?network=testnet',
      object: 'https://suiexplorer.com/object/{{object}}?network=testnet',
      coin: 'https://suiexplorer.com/object/{{coin}}?network=testnet',
    },
  },
  rpc: {
    suiscan: 'https://rpc-mainnet.suiscan.xyz/',
    sui_Official: 'https://fullnode.testnet.sui.io:443',
    suiet: ' https://mainnet.suiet.app/',
    shinami: 'https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa',
  },
  fullnode: 'https://fullnode.testnet.sui.io:443',
  fun: {
    originPackageId: '0xce12d6ae6064395d3782d0400e4e060d0531858f67b2f30832384719b95ca922',
    packageId: '0xce12d6ae6064395d3782d0400e4e060d0531858f67b2f30832384719b95ca922',
    globalConfig: '0xf3774738c96fdb11eb1fb91ea6f6afec832277a79d69df21a23674bbb50cd0dc',
    bytecodeBase64:
      'oRzrCwYAAAAKAQAMAgweAyonBFEIBVlXB7ABzAEI/AJgBtwDKwqHBAUMjAQsABABCwIGAhECEgITAAICAAEBBwEAAAIADAEAAQIDDAEAAQQEAgAFBQcAAAkAAQABDwUGAQACBwgJAQIDDAUBAQwDDQ4BAQwEDgsMAAUKAwQAAQQCBwMKBA0CCAAHCAQAAgsCAQgACwMBCAABCgIBCAUBCQABCwEBCQABCAAHCQACCgIKAgoCCwEBCAUHCAQCCwMBCQALAgEJAAELAgEIAAEGCAQBBQELAwEIAAIJAAUMQ29pbk1ldGFkYXRhBk9wdGlvbghURU1QTEFURQtUcmVhc3VyeUNhcAlUeENvbnRleHQDVXJsBGNvaW4PY3JlYXRlX2N1cnJlbmN5C2R1bW15X2ZpZWxkBGluaXQVbmV3X3Vuc2FmZV9mcm9tX2J5dGVzBm9wdGlvbhRwdWJsaWNfZnJlZXplX29iamVjdA9wdWJsaWNfdHJhbnNmZXIGc2VuZGVyBHNvbWUIdGVtcGxhdGUIdHJhbnNmZXIKdHhfY29udGV4dAN1cmwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAICAQYKAgcGU1lNQk9MCgIFBE5BTUUKAgwLREVTQ1JJUFRJT04KAgQDVVJMAAIBCAEAAAAAAhQLAAcABwEHAgcDBwQRBjgACgE4AQwCDAMLAjgCCwMLAS4RBTgDAgA=',
  },
};

export default development;
