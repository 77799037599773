import { defineModel } from 'foca';

const initialState: { slippage: string } = {
  slippage: '1',
};

export const slippageModel = defineModel('slippage', {
  initialState,
  reducers: {
    updateSlippage(state, value: string) {
      state.slippage = value;
    },
  },
});
