import { useRefreshFlag } from './use-refresh';
import { useEffect } from 'react';
import { useModel } from 'foca';
import { balanceModel } from '@models/balance.model';
import { throttle } from 'lodash-es';
import { useAddress } from './use-address';
import { normalizeSuiAddress } from '@mysten/sui/utils';
import { formatSuiNetworkAddress } from '@utils/sui-network-address';

const fetchBalances = throttle(balanceModel.getBalances, 10, { leading: true, trailing: false });

export const useBalances = () => {
  const address = useAddress();
  const { refresh } = useRefreshFlag();
  const balances = useModel(balanceModel, (state) => (address && state[address]) || []);

  useEffect(() => {
    address && fetchBalances(address);
  }, [refresh, address]);

  return balances;
};

export const useBalance = (coinType: string | undefined) => {
  const balances = useBalances();
  if (!coinType) return;
  return balances.find(
    (balance) =>
      formatSuiNetworkAddress(balance.coinType.toLocaleLowerCase()) ===
      formatSuiNetworkAddress(normalizeSuiAddress(coinType).toLocaleLowerCase()),
  )?.totalBalance;
};
