import { type FC, memo } from 'react';
import RcDialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import type { IDialogPropTypes } from 'rc-dialog/es/IDialogPropTypes';
import styles from './index.module.scss';
import closeIcon from '@assets/images/icons/close-circle.svg';

interface OwnProps extends IDialogPropTypes {
  /**
   * Defaults `true`
   */
  centered?: boolean;
}

const Dialog: FC<OwnProps> = ({ centered = true, className = '', children, ...props }) => {
  return (
    <RcDialog
      width={500}
      maskClosable={false}
      {...props}
      closeIcon={<img src={closeIcon} />}
      className={`${className} ${styles.dialog}`}
    >
      {props.visible ? children : undefined}
    </RcDialog>
  );
};

export default memo(Dialog);
