import { store, type Middleware } from 'foca';
import { globalSettingModel } from './global-setting.model';
import { NSFWOnOffModel } from './on-off.model';
import { slippageModel } from './slippage';
// import { createLogger } from 'redux-logger';
import { walletConnectModel } from './wallet-connect.model';

const middleware: Middleware[] = [];

if (process.env.NODE_ENV !== 'production') {
  // middleware.push(
  //   createLogger({
  //     collapsed: true,
  //     diff: true,
  //     duration: true,
  //     logErrors: true,
  //   }),
  // );
}

store.init({
  compose: 'redux-devtools',
  middleware,
  persist: [
    {
      key: 'fun-' + (import.meta.env.MODE === 'mainnet' ? 'production' : import.meta.env.MODE),
      version: 1,
      engine: localStorage,
      models: [globalSettingModel, walletConnectModel, NSFWOnOffModel, slippageModel],
    },
  ],
});

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('Store updated');
  });
}
