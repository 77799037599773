import {
  // useAccounts,
  // useSwitchAccount,
  useConnectWallet,
  useWallets,
} from '@mysten/dapp-kit';
import { useMemo, useEffect } from 'react';

interface LastConnectedWallet {
  lastConnectedWalletName: string | null;
  lastConnectedAccountAddress: string | null;
}

interface WalletConnectionInfo {
  state: LastConnectedWallet;
  version: number;
}

const getWalletNameFromStorage = () =>
  window.localStorage.getItem('sui-dapp-kit:wallet-connection-info');

export const setWalletNameFromStorage = (address: string) => {
  const lastWallet = getWalletNameFromStorage();
  let autoConnect: WalletConnectionInfo | null;
  if (lastWallet) {
    autoConnect = JSON.parse(lastWallet) as WalletConnectionInfo;
    autoConnect.state.lastConnectedAccountAddress = address;
    window.localStorage.setItem('sui-dapp-kit:wallet-connection-info', JSON.stringify(autoConnect));
  }
};

/**
 * Sometimes the wallet fail to connect wallet automatically without any reason.
 */
export const useAutoConnectWallet = () => {
  const { mutate: connect } = useConnectWallet();
  const wallets = useWallets();
  // const accounts = useAccounts();
  // const { mutate: switchAccount } = useSwitchAccount();
  const lastWallet = useMemo(getWalletNameFromStorage, []);
  let autoConnect: WalletConnectionInfo | null;
  if (lastWallet) {
    autoConnect = JSON.parse(lastWallet) as WalletConnectionInfo;
  }

  useEffect(() => {
    if (!autoConnect) return;
    const timer = setTimeout(() => {
      if (autoConnect?.state.lastConnectedWalletName) {
        const wallet = wallets.find(
          (wallet) => wallet.name === autoConnect?.state.lastConnectedWalletName,
        );
        wallet &&
          connect({
            wallet,
            silent: true,
          });
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   const account = accounts.find(
  //     (account) => account.address === autoConnect?.state.lastConnectedAccountAddress,
  //   );
  //   account && switchAccount({ account });
  // }, [accounts]);

  return lastWallet && autoConnect!.state.lastConnectedWalletName;
};
