import Dialog from '@ui/dialog';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import styles from './index.module.scss';
import workIcon from '@assets/images/pump/work.svg';

const PumpWork: FC = () => {
  const [visible, toggleVisible] = useToggle(false);
  return (
    <>
      <div className={styles.work} onClick={toggleVisible}>
        How it works?
      </div>

      <Dialog
        visible={visible}
        onClose={toggleVisible}
        title={'How lt Works?'}
        closable
        width={840}
      >
        <div className={styles.wrapper}>
          <div className={styles.top}>No presale, no free team allocation</div>
          <div className={styles.grid}>
            <div>
              <div className={styles.step}>Step 1</div>
              <p className={styles.des}>Pick a token that you like</p>
            </div>
            <div>
              <div className={styles.step}>Step 2</div>
              <p className={styles.des}>Buy the token on the bonding curve</p>
            </div>
            <div>
              <div className={styles.step}>Step 3</div>
              <p className={styles.des}>Sell at any time to lock in your profits or losses</p>
            </div>
          </div>

          <img src={workIcon} className={styles.img} />

          <div className={styles.grid_anther}>
            <div>
              <div className={styles.step}>Step 5</div>
              <p className={styles.des}>
                The initial liquidity on Turbos DEX is permanently locked (teams can earn
                fees)/burned (teams can’t earn fees)
              </p>
            </div>
            <div>
              <div className={styles.step}>Step 4</div>
              <p className={styles.des}>
                When 6000 $SUI is raised on the bonding curve, the pool is migrated and the token
                becomes tradable on Turbos DEX.
              </p>
            </div>
          </div>
          <div className={styles.btn}>
            <Link to="/" className={styles.explore} onClick={toggleVisible}>
              Explore Turbos.Fun
            </Link>
            <Link to="/launch" className={styles.launch} onClick={toggleVisible}>
              Launch Your Token
            </Link>
          </div>
          <div className={styles.bottom}>
            Turbos.Fun is a decentralized platform and does not endorse any meme coin. DYOR!
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PumpWork;
