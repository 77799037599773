import { defineModel } from 'foca';

const initialState: Record<string, boolean> = {
  onoff: false,
};

export const NSFWOnOffModel = defineModel('nsfw-on-ff', {
  initialState,
  reducers: {
    update(state, k: string, value: boolean) {
      state[k] = value;
    },
  },
});
