import { type FC, type ReactNode, memo, type MouseEventHandler } from 'react';
import styles from './index.module.scss';
import Loading from '../loading';
import { Link } from 'react-router-dom';

interface OwnProps {
  children: ReactNode;
  /**
   * Defaults `false`
   */
  loading?: boolean;
  /**
   * Defaults `false`
   */
  disabled?: boolean;
  /**
   * Defaults `normal`
   */
  type?: 'highlight' | 'normal';
  /**
   * Show highlight background while type is `normal`. Defaults `true`
   */
  hoverHighlight?: boolean;
  /**
   * Defaults `normal`
   */
  size?: 'small' | 'normal' | 'large';
  onClick?: MouseEventHandler;
  className?: string;
  /**
   * Defaults `false`
   */
  fullWidth?: boolean;
  icon?: string;
  iconHover?: string;
  /**
   * Use `<Link />` tag and onClick will be ignored. Link target will set to `_blank` by starting with http(s)
   */
  link?: string;
}

const Button: FC<OwnProps> = (props) => {
  const {
    loading = false,
    disabled = false,
    type = 'normal',
    size = 'normal',
    hoverHighlight = true,
    children,
    onClick,
    className,
    fullWidth = false,
    icon,
    iconHover = props.icon,
    link,
  } = props;

  const notAllowed = loading || disabled;
  const classnames: (string | undefined)[] = [
    styles.button,
    fullWidth ? '' : styles.auto_width,
    styles['size-' + size],
    notAllowed ? styles.disabled : '',
    type === 'highlight' ? styles['type-highlight'] : styles['type-normal'],
    hoverHighlight ? styles['hover-highlight'] : '',
    className,
  ].filter(Boolean);

  const $inner = (
    <>
      {loading ? (
        <Loading thinIcon={false} />
      ) : icon ? (
        <>
          <img src={icon} className={styles.icon_normal} />
          <img src={iconHover} className={styles.icon_hover} />
        </>
      ) : undefined}
      {children}
    </>
  );

  if (link) {
    return (
      <Link
        to={link}
        className={classnames.join(' ')}
        target={link.startsWith('http') ? '_blank' : '_self'}
      >
        {$inner}
      </Link>
    );
  }

  return (
    <div className={classnames.join(' ')} onClick={notAllowed ? undefined : onClick}>
      {$inner}
    </div>
  );
};

export default memo(Button);
