import { globalSettingModel } from '@models/global-setting.model';
import { NSFWOnOffModel } from '@models/on-off.model';
import { useResolveSuiNSName } from '@mysten/dapp-kit';
import { formatAddress } from '@mysten/sui/utils';
import { timeDifference } from '@utils/time-difference';
import { useModel } from 'foca';
import { OpenapiClientPump } from 'foca-openapi';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './list.module.scss';

interface OwnerProps {
  item: OpenapiClientPump.GetThreadListResponse['data'][number];
}

const List: FC<OwnerProps> = ({ item }) => {
  const onOff = useModel(NSFWOnOffModel, (state) => state['onoff']);
  const suiNames = useResolveSuiNSName(item.wallet_address);
  return (
    <li className={styles.li}>
      <div className={styles.address}>
        <Link to={globalSettingModel.explorerUrlForAccount(item.wallet_address)} target="_blank">
          {suiNames.data ? suiNames.data : formatAddress(item.wallet_address || '')}
        </Link>
        <span>{timeDifference(item.created_at)} ago</span>
      </div>
      <div className={styles.content}>
        {item.attach_image && onOff && <img src={item.attach_image} />}
        <div className={styles.text}>{item.content}</div>
      </div>
    </li>
  );
};

export default List;
