export const transactionErrorMap: Record<string, Record<string, string>> = {
  swap_router: {
    '2': 'Swap request timed out. Please try initiating the swap again.',
    '3': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '4': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '5': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
  },
  position_manager: {
    '8': 'Transaction request timed out. Please try again.',
  },
  pool: {
    '2': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '9': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '10': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '19': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '20': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
  },
};
