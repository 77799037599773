import { usePumpRankingLaunching, usePumpRankingMarketCap } from '@hooks/use-pump-ranking';
import { pumpSdk } from '@libs/pump';
import Loading from '@ui/loading';
import { formatDigitUnit } from '@utils/format-digit-unit';
import { type FC } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './index.module.scss';
import ranking1Icon from '@assets/images/pump/ranking_1.svg';
import ranking2Icon from '@assets/images/pump/ranking_2.svg';
import ranking3Icon from '@assets/images/pump/ranking_3.svg';
import Top from '@components/top';
import Footer from '@components/footer';
import { useNavigate } from 'react-router-dom';

const coins = [ranking1Icon, ranking2Icon, ranking3Icon];

const Ranking: FC = () => {
  const navigate = useNavigate();
  const launching = usePumpRankingLaunching();
  const marketCap = usePumpRankingMarketCap();

  return (
    <>
      <Top />
      <div className={styles.wrapper}>
        <div className={styles.title}>Turbos.Fun Ranking</div>

        <div className={styles.content}>
          <div>
            <div className={styles.top}>
              <p className={styles.column_title}>Racing on the Bonding Curve</p>
            </div>
            <div className={styles.items}>
              <ul className={styles.th}>
                <li>Project Name (Ticker)</li>
                <li className={styles.three}>Bonding Curve</li>
                <li className={styles.two}>Market Cap</li>
              </ul>

              {launching.loading && (
                <div className={styles.loading}>
                  <Loading />
                </div>
              )}

              {launching.launching?.map((item, index) => {
                let ratio = new Decimal(item.real_sui_reserves)
                  .div(pumpSdk.fair_launch_sui_amount)
                  .mul(100)
                  .toFixed(2);
                ratio = Number(ratio) > 100 ? '100' : ratio;
                const icon = coins[index];
                return (
                  <ul
                    className={styles.td}
                    key={item.token_address}
                    onClick={() => navigate(`/fun/${item.token_address}`)}
                  >
                    <li>
                      <div className={`${styles.ranking} ${styles['ranking' + index]}`}>
                        <span>{icon ? <img src={icon} /> : index + 1}</span>
                        <img src={item.token_metadata.iconUrl} />
                        <div>
                          {item.token_metadata.name} (${item.token_metadata.symbol})
                        </div>
                      </div>
                    </li>
                    <li className={styles.three}>
                      <div className={styles.value}>{ratio}%</div>
                      <div className={styles.percent}>
                        <div className={styles.width} style={{ width: `${ratio}%` }}></div>
                      </div>
                    </li>
                    <li className={styles.two}>${formatDigitUnit(item.market_cap_usd, 2)}</li>
                  </ul>
                );
              })}
            </div>
          </div>
          <div>
            <div className={styles.top}>
              <p className={styles.column_title}>Market Cap Ranking</p>
            </div>
            <div className={styles.items}>
              <ul className={`${styles.th} ${styles.th2}`}>
                <li>Name (Symbol)</li>
                <li className={styles.three}>Market Cap</li>
              </ul>
              {marketCap.loading && (
                <div className={styles.loading}>
                  <Loading />
                </div>
              )}
              {marketCap.marketCap?.map((item, index) => {
                const icon = coins[index];
                return (
                  <ul
                    className={`${styles.td} ${styles.td2}`}
                    key={item.token_address}
                    onClick={() => navigate(`/fun/${item.token_address}`)}
                  >
                    <li>
                      <div className={`${styles.ranking} ${styles['ranking' + index]}`}>
                        <span>{icon ? <img src={icon} /> : index + 1}</span>
                        <img src={item.token_metadata.iconUrl} />
                        <div>
                          {item.token_metadata.name} (${item.token_metadata.symbol})
                        </div>
                      </div>
                    </li>
                    <li className={styles.three}>${formatDigitUnit(item.market_cap_usd, 2)}</li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ranking;
