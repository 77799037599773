import { turbosSdk } from '@libs/turbos-sdk';
import { walletConnectModel } from '@models/wallet-connect.model';
import { SuiClient } from '@mysten/sui/client';
import { useModel } from 'foca';
import { useEffect } from 'react';

let provider = turbosSdk.provider;
if (process.env.NODE_ENV !== 'production') {
  provider = new SuiClient({ url: 'https://fullnode.mainnet.sui.io:443' });
}

export const useAddressDomain = (...addresses: string[]) => {
  const maps = useModel(walletConnectModel, (state) => state.addressDomains);

  useEffect(() => {
    addresses.filter(Boolean).forEach(async (address) => {
      const prevFetchTime = walletConnectModel.state.addressDomains[address]?.time;
      if (prevFetchTime && Date.now() - prevFetchTime <= 1800_000) return;

      const result: { data: string[] } = await provider.call('suix_resolveNameServiceNames', [
        address,
      ]);
      return walletConnectModel.updateAddressName(address, result.data[0]);
    });
  }, [addresses.sort().join(',')]);

  return addresses.map((address) => maps[address]?.domain);
};
