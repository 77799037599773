import { defineModel } from 'foca';

export const walletConnectModel = defineModel('wallet-connect', {
  initialState: {
    openDialog: false,
    showWallet: true,
    addressDomains: {} as Record<string, { domain: string | undefined; time: number }>,
  },
  reducers: {
    toggleDialog(state) {
      state.openDialog = !state.openDialog;
    },
    showWallet(state, show: boolean) {
      state.showWallet = show;
    },
    updateAddressName(state, address: string, name: string | undefined) {
      state.addressDomains[address] = {
        domain: name ? '@' + name : name,
        time: Date.now(),
      };
    },
  },
  persist: {
    version: 2,
    dump(state) {
      return state.addressDomains;
    },
    load(dumpData) {
      return { ...this.initialState, addressDomains: dumpData };
    },
  },
});
