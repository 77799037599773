import { pumpApi } from '@libs/openapi';
import { defineModel } from 'foca';
import { OpenapiClientPump } from 'foca-openapi';

const initialState: {
  comments: Record<string, OpenapiClientPump.GetThreadListResponse>;
} = {
  comments: {},
};

export const pumpThreadModel = defineModel('pump-thread', {
  initialState,
  methods: {
    async fetchThreadList(tokenAddress: string, page: number = 10, pageSize: number = 20) {
      const result = await pumpApi.thread.getThreadList({
        query: { page, tokenAddress, pageSize },
      });
      this.setState((state) => {
        state.comments[tokenAddress] = result;
      });
      return result;
    },
  },
});
