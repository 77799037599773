import { type FC, memo } from 'react';
import styles from './social.module.scss';
import contactIcon from '@assets/images/icons/contact.svg';
import Tooltip from '@ui/tooltip';
import Links from './links';

const Social: FC = () => {
  return (
    <Tooltip placement="bottom" overlay={<Links />}>
      <div className={styles['contact-box']}>
        <img src={contactIcon} />
      </div>
    </Tooltip>
  );
};

export default memo(Social);
