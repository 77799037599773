import { SUI_CLOCK_OBJECT_ID } from '@mysten/sui/utils';
import { coinWithBalance, Transaction } from '@mysten/sui/transactions';
import { SuiClient } from '@mysten/sui/client';
import { Decimal, unstable_getObjectFields } from 'turbos-clmm-sdk';
import { turbosSdk } from './turbos-sdk';
import { bcs } from '@mysten/sui/bcs';
import init, * as template from '@mysten/move-bytecode-template';
import url from '@mysten/move-bytecode-template/move_bytecode_template_bg.wasm?url';
import { config } from '@config';
import { LRUCache } from 'lru-cache';
import { Buffer } from 'buffer';

const poolCache = new LRUCache({
  max: 100,
  ttl: 1000 * 10,
});

const uri = url;
const pakcageId = config.fun.packageId;
const globalConfig = config.fun.globalConfig;
const bytecodeBase64 = config.fun.bytecodeBase64;

interface CreatePoolOptions {
  coinTreasuryCap: string;
  coinType: string;
  name: string;
  symbol: string;
  url: string;
  description: string;
  twitter: string;
  telegram: string;
  website: string;
  currentAddress: string;
  lp_type: 0 | 1;
  tx?: Transaction;
}

interface PublishOptions
  extends Pick<CreatePoolOptions, 'name' | 'symbol' | 'url' | 'description' | 'currentAddress'> {}

interface BuyInitOptions {
  coinType: string;
  amount: string;
  walletAddress: string;
  isExact: boolean;
  tx?: Transaction;
}

interface BuyOptions extends BuyInitOptions {
  isExact: boolean;
  threshold: string;
}

interface sellOptions extends BuyOptions {}

export interface CalculateAmountOptions {
  coinType: string;
  amount: string;
  threshold: string;
  atob: boolean;
  isExact: boolean;
  isInit?: boolean;
}

interface GlobalConfigFileld {
  fair_launch_sui_amount: string;
  platform_fee: string;
  initial_virtual_sui_reserves: string;
  initial_virtual_token_reserves: string;
  remain_token_reserves: string;
  token_decimals: number;
  deployment_fee: string;
}

export interface PoolFields {
  is_completed: boolean;
  real_sui_reserves: string;
  real_token_reserves: string;
  remain_token_reserves: string;
  virtual_sui_reserves: string;
  virtual_token_reserves: string;
}

class Pump {
  client: SuiClient;

  quote_symbol: string = 'SUI';
  quote_address: string = '0x2::sui::SUI';
  quote_decimals: number = 9;
  quote_logo_url: string = 'https://app.turbos.finance/icon/SUI-30.svg';

  token_decimals: number = 6;
  token_threshold: number = 1;
  initial_virtual_sui_reserves: Decimal = new Decimal(1_500_000_000_000);
  initial_virtual_token_reserves: Decimal = new Decimal(10_000_000_000_000_000);
  remain_token_reserves: Decimal = new Decimal(20_00_000_000_000);
  fair_launch_sui_amount: string = '6_000_000_000_000';
  platform_fee: number = 0.005;
  deployment_fee: number = 0;

  constructor(readonly provider: SuiClient) {
    this.client = provider;
    this.init();
  }

  async init() {
    const result = await turbosSdk.provider.getObject({
      id: globalConfig,
      options: {
        showContent: true,
      },
    });
    const fields = unstable_getObjectFields(result) as unknown as GlobalConfigFileld;

    this.fair_launch_sui_amount = fields.fair_launch_sui_amount;
    this.platform_fee = new Decimal(fields.platform_fee).div(1000000).toNumber();
    this.initial_virtual_sui_reserves = new Decimal(fields.initial_virtual_sui_reserves);
    this.initial_virtual_token_reserves = new Decimal(fields.initial_virtual_token_reserves);
    this.remain_token_reserves = new Decimal(fields.remain_token_reserves);
    this.token_decimals = fields.token_decimals;
    this.deployment_fee = new Decimal(fields.deployment_fee)
      .div(10 ** this.quote_decimals)
      .toNumber();
  }

  async publish({ name, symbol, url, description, currentAddress }: PublishOptions) {
    await init(uri);
    let bytecode = Uint8Array.from(Buffer.from(bytecodeBase64, 'base64'));
    let updated;
    updated = template.update_constants(
      bytecode,
      bcs.u8().serialize(this.token_decimals).toBytes(),
      bcs.u8().serialize(6).toBytes(),
      'U8',
    );
    updated = template.update_constants(
      updated,
      bcs.vector(bcs.u8()).serialize(Buffer.from(name)).toBytes(),
      bcs.vector(bcs.u8()).serialize(Buffer.from('NAME')).toBytes(),
      'Vector(U8)',
    );
    updated = template.update_constants(
      updated,
      bcs.vector(bcs.u8()).serialize(Buffer.from(symbol)).toBytes(),
      bcs.vector(bcs.u8()).serialize(Buffer.from('SYMBOL')).toBytes(),
      'Vector(U8)',
    );
    updated = template.update_constants(
      updated,
      bcs.vector(bcs.u8()).serialize(Buffer.from(description)).toBytes(),
      bcs.vector(bcs.u8()).serialize(Buffer.from('DESCRIPTION')).toBytes(),
      'Vector(U8)',
    );
    updated = template.update_constants(
      updated,
      bcs.vector(bcs.u8()).serialize(Buffer.from(url)).toBytes(),
      bcs.vector(bcs.u8()).serialize(Buffer.from('URL')).toBytes(),
      'Vector(U8)',
    );
    updated = template.update_identifiers(updated, {
      TEMPLATE: symbol.toUpperCase(),
      template: symbol.toLowerCase(),
    });

    const txb = new Transaction();
    const [upgradeCap] = txb.publish({
      modules: [Buffer.from(updated).toString('base64')],
      dependencies: [
        '0x0000000000000000000000000000000000000000000000000000000000000001',
        '0x0000000000000000000000000000000000000000000000000000000000000002',
      ],
    });
    txb.transferObjects([upgradeCap!], currentAddress);
    return txb;
  }

  async createPool({
    coinTreasuryCap,
    coinType,
    name,
    symbol,
    url,
    description,
    twitter,
    telegram,
    website,
    currentAddress,
    lp_type,
    tx,
  }: CreatePoolOptions) {
    const txb = tx || new Transaction();

    const apyAmount = Math.round(
      new Decimal(this.deployment_fee).mul(10 ** this.quote_decimals).toNumber(),
    );

    const paySuiCoin = coinWithBalance({
      type: '0x2::sui::SUI',
      balance: apyAmount,
    });

    txb.setSenderIfNotSet(currentAddress);
    txb.moveCall({
      target: `${pakcageId}::turbospump::create`,
      arguments: [
        txb.object(globalConfig),
        txb.object(coinTreasuryCap),
        paySuiCoin,
        txb.pure.string(name),
        txb.pure.string(symbol),
        txb.pure.string(url),
        txb.pure.string(description),
        txb.pure.string(twitter),
        txb.pure.string(telegram),
        txb.pure.string(website),
        txb.pure.u8(lp_type),
        txb.object(SUI_CLOCK_OBJECT_ID),
      ],
      typeArguments: [coinType],
    });

    return txb;
  }

  async buy(options: BuyOptions) {
    const { tx, coinType, isExact, threshold, amount, walletAddress } = options;
    const txb = tx || new Transaction();

    const coinAmount = new Decimal(amount)
      .mul(10 ** (isExact ? this.quote_decimals : this.token_decimals))
      .toFixed(0);

    const { tokenAmount, thresholdAmount } = await this.calculateTokenAndThresholdAmount({
      coinType,
      threshold,
      amount: coinAmount,
      atob: true,
      isExact,
    });

    const coinBalance = coinWithBalance({
      type: this.quote_address,
      balance: Number(isExact ? coinAmount : tokenAmount),
    });

    txb.setSenderIfNotSet(walletAddress);
    txb.moveCall({
      target: `${pakcageId}::turbospump::buy`,
      arguments: [
        txb.object(globalConfig),
        coinBalance,
        txb.pure.u64(coinAmount),
        txb.pure.u64(thresholdAmount),
        txb.pure.bool(isExact),
        txb.object(SUI_CLOCK_OBJECT_ID),
      ],
      typeArguments: [coinType],
    });
    return txb;
  }

  async sell(options: sellOptions) {
    const { coinType, tx, walletAddress, isExact, threshold, amount } = options;
    const txb = tx || new Transaction();
    const coinAmount = new Decimal(amount)
      .mul(10 ** (!isExact ? this.quote_decimals : this.token_decimals))
      .toFixed(0);
    const { tokenAmount, thresholdAmount } = await this.calculateTokenAndThresholdAmount({
      coinType,
      threshold,
      amount: coinAmount,
      atob: false,
      isExact,
    });
    const coinBalance = coinWithBalance({
      type: coinType,
      balance: Number(isExact ? coinAmount : tokenAmount),
    });
    console.log(coinAmount, tokenAmount, thresholdAmount);
    txb.setSenderIfNotSet(walletAddress);
    txb.moveCall({
      target: `${pakcageId}::turbospump::sell`,
      arguments: [
        txb.object(globalConfig),
        coinBalance,
        txb.pure.u64(coinAmount),
        txb.pure.u64(thresholdAmount),
        txb.pure.bool(isExact),
        txb.object(SUI_CLOCK_OBJECT_ID),
      ],
      typeArguments: [coinType],
    });
    return txb;
  }

  async calculateTokenAndThresholdAmount(options: CalculateAmountOptions) {
    const { coinType, atob, isExact, threshold, isInit = false } = options;
    const poolInfo = !isInit ? await this.getPoolInfo(coinType) : undefined;

    const virtual_token_reserves = new Decimal(
      poolInfo ? poolInfo.virtual_token_reserves : this.initial_virtual_token_reserves,
    );
    const virtual_sui_reserves = new Decimal(
      poolInfo ? poolInfo.virtual_sui_reserves : this.initial_virtual_sui_reserves,
    );
    const amount = new Decimal(options.amount);

    let _amount: Decimal;
    let _amountThreshold: Decimal;

    if (atob) {
      if (isExact) {
        const _fee = new Decimal(1).sub(this.platform_fee); // 0.005 fee
        _amount = virtual_token_reserves.sub(
          virtual_sui_reserves
            .mul(virtual_token_reserves)
            .div(virtual_sui_reserves.add(amount.mul(_fee))),
        );
        _amountThreshold = new Decimal(100).sub(threshold).div(100).mul(_amount);
      } else {
        const _fee = new Decimal(1).add(this.platform_fee); // 0.005 fee
        const _threshold = new Decimal(100).add(threshold).div(100);
        _amount = virtual_sui_reserves
          .mul(virtual_token_reserves)
          .div(virtual_token_reserves.sub(amount))
          .sub(virtual_sui_reserves)
          .mul(_fee)
          .mul(_threshold);

        _amountThreshold = _amount;
      }
    } else {
      if (isExact) {
        const _fee = new Decimal(1).sub(this.platform_fee); // 0.005 fee
        _amount = virtual_sui_reserves
          .sub(
            virtual_sui_reserves
              .mul(virtual_token_reserves)
              .div(virtual_token_reserves.add(amount)),
          )
          .mul(_fee);

        _amountThreshold = new Decimal(100).sub(threshold).div(100).mul(_amount);
      } else {
        const _fee = new Decimal(1).add(this.platform_fee); // 0.005 fee
        _amount = virtual_token_reserves.sub(
          virtual_sui_reserves
            .mul(virtual_token_reserves)
            .div(virtual_sui_reserves.add(amount.mul(_fee))),
        );

        _amountThreshold = new Decimal(100).add(threshold).div(100).mul(_amount);
      }
    }

    return {
      tokenAmount: _amount.toFixed(0),
      thresholdAmount: _amountThreshold.toFixed(0),
    };
  }

  calculateInitTokenAmount(amount: string) {
    const _fee = new Decimal(1).sub(this.platform_fee); // 0.005 fee
    const _amount = new Decimal(amount);
    return this.initial_virtual_token_reserves.sub(
      this.initial_virtual_sui_reserves
        .mul(this.initial_virtual_token_reserves)
        .div(this.initial_virtual_sui_reserves.add(_amount.mul(_fee)))
        .toString(),
    );
  }

  async getCoinTreasuryCap(digest: string) {
    let coinTreasuryCap = '';
    let coinType = '';
    const response = await this.client.waitForTransaction({
      digest,
      options: {
        showEffects: true,
        showObjectChanges: true,
      },
    });

    const effects = response.effects;
    if (effects?.status.status === 'success') {
      response.objectChanges?.forEach((object) => {
        if (object.type == 'created' && object.objectType.startsWith('0x2::coin::TreasuryCap')) {
          coinTreasuryCap = object.objectId;
          const match = object.objectType.match(
            /0x[a-fA-F0-9]{64}::[a-zA-Z_][a-zA-Z0-9_]*::[a-zA-Z_][a-zA-Z0-9_]*/,
          );
          coinType = match ? match[0]! : '';
        }
      });
      return {
        coinTreasuryCap,
        coinType,
      };
    } else {
      throw new Error(effects?.status.error || '');
    }
  }

  getTokenRatioForAmount(amount: string) {
    return new Decimal(amount).div(this.initial_virtual_token_reserves).mul(100).toString();
  }

  getTokenAmountForRatio(ratio: string) {
    return new Decimal(ratio)
      .div(100)
      .mul(this.initial_virtual_token_reserves)
      .div(10 ** this.token_decimals)
      .toString();
  }

  async getPoolInfo(coinType: string, cache: boolean = true) {
    if (cache) {
      const poolFields = poolCache.get(coinType);
      if (poolFields) return poolFields as PoolFields;
    }

    let coinId = coinType.split('::')[0]?.slice(2);
    if (!coinId) {
      return;
    }
    try {
      const res = await this.client.getDynamicFieldObject({
        parentId: globalConfig,
        name: {
          type: '0x1::ascii::String',
          value: coinId,
        },
      });
      const fields = unstable_getObjectFields(res) as unknown as PoolFields;
      poolCache.set(coinType, fields);
      return fields;
    } catch (err) {}
    return;
  }

  protected async getCoinMetadata(coinType: string) {
    const result = await this.client.getCoinMetadata({ coinType });
    return result;
  }
}

export const pumpSdk = new Pump(turbosSdk.provider);
