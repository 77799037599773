import Tooltip from '@ui/tooltip';
import { type FC, memo, useState, type ChangeEventHandler } from 'react';
import styles from './index.module.scss';
import Input from '@ui/input';
import slippageIcon from '@assets/images/pump/slippage.svg';
import Button from '@ui/button';
import { useToggle } from 'react-use';
import { useModel } from 'foca';
import { slippageModel } from '@models/slippage';

const SelectSlippage: FC = () => {
  const [visible, toggleVisible] = useToggle(false);
  const slippage = useModel(slippageModel, (state) => state.slippage);
  return (
    <Tooltip
      showArrow={false}
      trigger={'click'}
      placement="bottomRight"
      visible={visible}
      overlay={<Setting onChangeVisible={toggleVisible} />}
      overlayClassName={styles.tooltip}
      onVisibleChange={toggleVisible}
    >
      <div className={styles.button}>
        <img src={slippageIcon} data-icon />
        {slippage}%
      </div>
    </Tooltip>
  );
};

const Setting: FC<{ onChangeVisible(visible: boolean): void }> = ({ onChangeVisible }) => {
  const slippage = useModel(slippageModel, (state) => state.slippage);

  const [inputValue, setInputValue] = useState(slippage);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = Number(e.target.value) >= 0 ? e.target.value : '1';
    setInputValue(value);
  };

  return (
    <>
      <div className={styles.setting_title}>Set max. slippage (%)</div>

      <div className={styles.input_wrapper}>
        <Input type="text" value={inputValue} placeholder="1" onChange={handleChange} suffix="%" />
      </div>
      <div className={styles.text}>
        This is the maximum amount of slippage you are willing to accept when placing trades.(should
        be between 1%-20%)
      </div>
      <div className={styles.btn}>
        <Button onClick={() => onChangeVisible(false)}>Cancel</Button>
        <Button
          type="highlight"
          onClick={() => {
            slippageModel.updateSlippage(inputValue);
            onChangeVisible(false);
          }}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default memo(SelectSlippage);
