import { useAddress } from '@hooks/use-address';
import { FC, memo, useState } from 'react';
import styles from './submit.module.scss';
import uploadIcon from '@assets/images/pump/upload-icon.svg';
import closeIcon from '@assets/images/pump/close.svg';
import { useDropzone } from 'react-dropzone';
import http from '@libs/http';
import { useSignPersonalMessage } from '@mysten/dapp-kit';
import { useRefreshFlag } from '@hooks/use-refresh';
import { OpenapiClientPump } from 'foca-openapi';
import Tooltip from '@ui/tooltip';
import { walletConnectModel } from '@models/wallet-connect.model';
import { toast } from 'react-toastify';
import { isAxiosError } from 'foca-axios';
interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
  onChangeText(value: string): void;
  onChangeLoading(value: boolean): void;
  text: string;
}

const Submit: FC<OwnerProps> = ({ pool, onChangeText, text, onChangeLoading }) => {
  const address = useAddress();
  const { notifyRefresh } = useRefreshFlag();
  const { mutateAsync: signMessage } = useSignPersonalMessage();

  const [file, setFile] = useState<string>('');
  const [data, setData] = useState<FormData>();

  const onDrop = (acceptedFiles: File[]) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', file);

      setData(formData);
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          setFile(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
  });

  const handleMessage = async () => {
    onChangeLoading(true);
    try {
      const result = await http.post<{ nonce: string }>('/passport/nonce', {
        walletAddress: address,
      });
      const signResult = await signMessage({
        message: new TextEncoder().encode(result.nonce),
      });
      const res = await http.post<{
        expires_at: number;
        token: string;
        walletAddress: string;
      }>('/passport/auth', {
        walletAddress: address,
        signature: signResult.signature,
      });

      const ok = await http.post<{ id: number }>(
        '/thread',
        {
          tokenAddress: pool.token_address,
          content: text,
          file: data?.get('file'),
        },
        {
          headers: {
            'Authorization': `Bearer ${res.token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (ok.id) {
        onChangeText('');
        setFile('');
        setData(undefined);
        notifyRefresh();
      }
    } catch (err) {
      if (isAxiosError<{ message: string }>(err)) {
        toast(err.response?.data.message, { type: 'error' });
      } else {
        console.log(err);
      }
    }
    onChangeLoading(false);
  };

  const disabled = !!(address && text.length === 0);

  return (
    <div className={styles.wrapper}>
      {file && (
        <div className={styles.file}>
          <img src={file} />
          <div
            className={styles.close}
            onClick={() => {
              setFile('');
              setData(undefined);
            }}
          >
            <img src={closeIcon} />
          </div>
        </div>
      )}
      <div className={styles.submit}>
        <Tooltip overlay={'MaxSize:2M'} placement="bottom">
          <div {...getRootProps({ className: 'dropzone' })} className={styles.upload}>
            <img src={uploadIcon} />
            <input {...getInputProps()} />
          </div>
        </Tooltip>
        <p>{text.length}/256</p>
        <button
          disabled={disabled}
          onClick={!address ? walletConnectModel.toggleDialog : handleMessage}
          className={styles.button}
        >
          {address ? 'Submit' : 'Connect Wallet'}
        </button>
      </div>
    </div>
  );
};

export default memo(Submit);
