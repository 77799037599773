import { type FC } from 'react';
import styles from './index.module.scss';
import emptyIcon from '@assets/images/pump/empty.svg';
const PumpEmpty: FC = () => {
  return (
    <div className={styles.empty}>
      <img src={emptyIcon} />
      <div className={styles.no}>No items yet</div>
    </div>
  );
};

export default PumpEmpty;
