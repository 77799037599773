import { usePumpCalculateTokenAndThresholdAmount } from '@hooks/use-pump-amount';
import { type PoolFields, pumpSdk } from '@libs/pump';
import Input from '@ui/input';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { filterPositiveNumber } from '@utils/filter-positive-number';
import { OpenapiClientPump } from 'foca-openapi';
import { useEffect, useState, type FC } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './buy.module.scss';

export const ratioList = [
  {
    label: '0.1',
    value: '0.1',
  },
  {
    label: '0.2',
    value: '0.2',
  },
  {
    label: '0.5',
    value: '0.5',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '5',
    value: '5',
  },
];

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
  poolFields: PoolFields;
  atob: boolean;
  isExact: boolean;
  amount: string | undefined;
  onChangeAmount(amount: string): void;
  balance: string;
  tipText: string;
  avaiableBalance: string;
  receiveAmount: string;
  isOver: boolean;
  slippage: string;
  onChangeRatioAmount(amount: string): void;
}

const Buy: FC<OwnerProps> = ({
  pool,
  poolFields,
  atob,
  isExact,
  amount,
  onChangeAmount,
  balance,
  tipText,
  avaiableBalance,
  receiveAmount,
  isOver,
  slippage,
  onChangeRatioAmount,
}) => {
  const [ratio, setRatio] = useState('');

  const amountForRatio = pumpSdk.getTokenAmountForRatio(ratio || '0');
  const availableRatio = pumpSdk.getTokenRatioForAmount(poolFields.real_token_reserves);

  const receive = usePumpCalculateTokenAndThresholdAmount({
    coinType: pool.token_address,
    amount: new Decimal(amountForRatio).mul(10 ** pumpSdk.token_decimals).toString(),
    threshold: slippage,
    atob: true,
    isExact: false,
  });

  useEffect(() => {
    if (receive?.thresholdAmount && receive.thresholdAmount !== '0') {
      onChangeAmount(
        new Decimal(receive.thresholdAmount).div(10 ** pumpSdk.quote_decimals).toString(),
      );
    }
  }, [receive?.thresholdAmount]);

  useEffect(() => {
    setRatio('');
  }, [isExact]);

  useEffect(() => {
    if (amountForRatio !== '0') {
      onChangeRatioAmount(amountForRatio);
    } else {
      onChangeRatioAmount('');
    }
  }, [amountForRatio]);

  const _balance = isOver ? avaiableBalance : receiveAmount;
  const receiveAmountRatio = pumpSdk.getTokenRatioForAmount(
    new Decimal(_balance).mul(10 ** pumpSdk.token_decimals).toString(),
  );
  const finalRatio = !!ratio ? ratio : receiveAmountRatio;

  const _elementSymbol = `${bigNumberToReadable(avaiableBalance, 0, true)} (${bigNumberToReadable(
    availableRatio,
    0,
    false,
    2,
  )}%) $${atob ? pool.symbol : pumpSdk.quote_symbol}`;
  return (
    <>
      <Input
        placeholder="Enter the amount"
        wrapperClassName={`${styles.input} ${isExact ? styles.input_balance : ''}`}
        value={amount}
        onChangeText={(value) => {
          setRatio('');
          const result = filterPositiveNumber(value);
          onChangeAmount(result.str);
        }}
        suffix={
          <>
            <div className={styles.coin}>
              <span>{(isExact && atob) || (!isExact && !atob) ? 'SUI' : pool.symbol}</span>
              <img
                src={
                  (isExact && atob) || (!isExact && !atob)
                    ? pumpSdk.quote_logo_url
                    : pool.token_metadata.iconUrl
                }
              />
            </div>
            {isExact && (
              <div className={styles.balance}>
                Balance: <span>{bigNumberToReadable(balance, 0, true)}</span>
                <div
                  onClick={() => {
                    setRatio('');
                    onChangeAmount(new Decimal(balance).div(2).toString());
                  }}
                >
                  Half
                </div>
                <div
                  onClick={() => {
                    setRatio('');
                    onChangeAmount(new Decimal(balance).sub(0.1).toString());
                  }}
                >
                  Max
                </div>
              </div>
            )}
          </>
        }
      />

      {!!tipText && <div className={styles.error_tip}>{tipText}</div>}

      {isExact && (
        <>
          <div className={styles.or_select}>Or choose a % of ${pool.symbol} total supply</div>
          <ul className={styles.sui_tab}>
            {ratioList.map((item) => {
              const disabled = new Decimal(item.value).gt(availableRatio);
              return (
                <li
                  onClick={() => {
                    if (disabled) return;
                    setRatio(item.value);
                  }}
                  key={item.value}
                  className={`${ratio === item.value ? styles.active : ''} ${
                    disabled ? styles.disabled : ''
                  }`}
                >
                  {item.label}%
                </li>
              );
            })}
          </ul>
        </>
      )}

      <div className={styles.line}>
        <p>Available to buy:</p>
        <p className={_elementSymbol.length > 33 ? styles.minsize : ''}>{_elementSymbol}</p>
      </div>

      <div className={styles.line}>
        <p>You will {isExact ? 'receive' : 'pay'}:</p>
        <p className={_elementSymbol.length > 33 ? styles.minsize : ''}>
          {bigNumberToReadable(amountForRatio === '0' ? _balance : amountForRatio, 0, true)}{' '}
          {isExact && finalRatio !== '0'
            ? `(${bigNumberToReadable(finalRatio, 0, false, 2)}%) `
            : ' '}
          {isExact ? `$${pool.symbol}` : pumpSdk.quote_symbol}
        </p>
      </div>

      {!isExact && (
        <div className={styles.line}>
          <p>Balance:</p>
          <p>
            {bigNumberToReadable(balance, 0, true)} {atob ? 'SUI' : pool.symbol}
          </p>
        </div>
      )}
    </>
  );
};

export default Buy;
